import flattenObjectKeys from '../../../utils/flat-object/flatObject'
import { ApiQuery, ApiServiceParams } from '../commons'

export const buildQuery = (params?: ApiServiceParams | ApiQuery) => {
  if (!params) {
    return []
  }

  const query: string[] = []
  const formatted = flattenObjectKeys(params.query, '', ['page', 'filter'])

  Object.keys(formatted).forEach((key) => {
    const value =
      typeof formatted[key] === 'object'
        ? JSON.stringify(formatted[key])
        : formatted[key]
    query.push(encodeURIComponent(key) + '=' + encodeURIComponent(value))
  })

  return query
}
