import { yupResolver } from '@hookform/resolvers/yup'
import React, { useState } from 'react'
import { useForm, FormProvider, Controller } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router-dom'
import { tss } from 'tss-react/mui'
import * as yup from 'yup'

import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined'
import { Box, Tooltip, IconButton, Grid } from '@mui/material'

import { api, Transaction } from '@shared/api'
import {
  ActionModal,
  FieldGroupRowStacked,
  PageLayoutDivider,
  Input,
  Checkbox,
  FieldGroupViewItemStacked,
} from '@shared/components'
import { useNotification } from '@shared/hooks'
import { mapTransactionTypeId } from '@shared/mapping/gateway-transactions'
import { currency, toArrayFieldErrors } from '@shared/utils'

const useStyles = tss.withName('ACHRetryModal').create(({ theme }) => ({
  modal: {
    maxWidth: '800px',
    minWidth: '500px',
    width: '100%',
    maxHeight: '800px',
    height: 'auto',
  },
  fixCheckboxHover: {
    overflow: 'visible',
    position: 'relative',
    paddingLeft: '10px',
  },
  totalAmountContainer: {
    display: 'flex',
    padding: '12px 16px',
    alignItems: 'center',
    gap: '4px',
    borderRadius: '6px',
    border: '1px solid #E5E7EB',
    background: '#F3F4F6',
    justifyContent: 'space-between',
    marginTop: theme.spacing(2),
  },
  totalAmountLabel: {
    color: '#374151',
    fontFamily: 'Inter',
    fontSize: '14px',
    fontWeight: 500,
    lineHeight: '20px',
  },
  totalAmountValue: {
    color: '#111827',
    fontFamily: 'Inter',
    fontSize: '18px',
    fontWeight: 600,
    lineHeight: '28px',
    textAlign: 'right',
  },
  itemLabel: {
    color: '#4B5563',
    fontFamily: 'Inter',
    fontSize: '14px',
    fontWeight: 400,
    lineHeight: '20px',
    marginBottom: theme.spacing(0.5),
  },
  tooltip: {
    maxWidth: '500px',
    whiteSpace: 'nowrap',
  },
}))

interface ACHRetryModalProps {
  open: boolean
  onClose: () => void
  transaction: Transaction
  retryEntries: any[]
}

const schema = (t) =>
  yup.object().shape({
    customer_consent: yup.boolean().required(t('common.consent-required')),
    transaction: yup.array(),
    retryEntries: yup.array(),
    return_fee: yup
      .string()
      .nullable()
      .transform((value, originalValue) =>
        originalValue === null ? undefined : value
      )
      .when('customer_consent', {
        is: true,
        then: (schema) =>
          schema
            .test(
              'min-value',
              t('common.return-fee-must-be-at-least-1-cent'),
              (value) => {
                if (!value) return false
                const numeric = parseFloat(value)
                return numeric >= 0.01
              }
            )
            .test(
              'max-value',
              t('common.return-fee-can-be-up-to-35'),
              (value) => !value || parseFloat(value) <= 35
            )
            .required(t('common.return-fee-required')),
        otherwise: (schema) => schema.notRequired(),
      }),
  })

const ACHRetryModal: React.FC<ACHRetryModalProps> = ({
  open,
  onClose,
  transaction,
  retryEntries,
}) => {
  const { classes } = useStyles()
  const { t } = useTranslation()
  const { setNotification } = useNotification()
  const navigate = useNavigate()
  const [isLoading, setIsLoading] = useState<boolean>(false)

  const formMethods = useForm({
    defaultValues: {
      customer_consent: false,
      return_fee: undefined,
    },
    resolver: yupResolver(schema(t)),
    mode: 'onBlur',
  })

  const { watch, reset } = formMethods

  const attemptNumber = Math.min(retryEntries.length + 1, 2)

  const modalTitle = `${t('common.retry-payment')} - ${t(
    'common.retry-entry-history.attempt'
  )} ${attemptNumber} ${t('common.retry-entry-history.out-of')} 2`

  const nachaTooltip = t('common.retry-entry-history.nacha-tooltip')

  const transactionAmount = transaction?.transaction_amount ?? 0

  const isConsent = watch('customer_consent')
  const returnFeeValue = watch('return_fee')
  const taxFee =
    isConsent && returnFeeValue
      ? Math.round(parseFloat(returnFeeValue) * 100)
      : 0

  const totalInCents = transactionAmount + taxFee

  const handleClose = () => {
    reset({
      customer_consent: false,
      return_fee: undefined,
    })
    onClose()
  }

  const onSubmit = async (formValues: any) => {
    if (!transaction) return
    setIsLoading(true)
    try {
      const feeCents =
        formValues.customer_consent && formValues.return_fee
          ? Math.round(parseFloat(formValues.return_fee) * 100)
          : undefined

      const response = await api.service('transaction-ach-retries').create({
        rejected_transaction_id: transaction.id,
        return_fee: feeCents,
      })
      handleClose()
      navigate(
        `/merchant/gateway/virtual-terminal/confirmation/${response.retry_transaction_id}`
      )
    } catch (error) {
      const errors = toArrayFieldErrors(error)
      if (errors.some((err) => err.field === 'return_fee')) {
        const returnFeeError = errors.find(
          (err) => err.field === 'return_fee'
        )?.message
        formMethods.setError('return_fee', { message: returnFeeError })
        setNotification({
          type: 'error',
          label: returnFeeError,
        })
      } else {
        const messages = errors.map((error) => error.message)
        setNotification({
          type: 'error',
          label: messages.join(' | '),
        })
      }
    } finally {
      setIsLoading(false)
    }
  }

  const renderLayout = () => {
    if (!transaction) return null

    return (
      <>
        <FieldGroupRowStacked spacing={2}>
          <FieldGroupViewItemStacked
            title={t('common.merchant-account')}
            value={transaction.product_transaction?.title}
          />
          <FieldGroupViewItemStacked
            title={t('common.account-last-four')}
            value={transaction.last_four}
          />
        </FieldGroupRowStacked>

        <FieldGroupRowStacked spacing={2}>
          <FieldGroupViewItemStacked
            title={t('common.transaction-type')}
            value={mapTransactionTypeId(transaction.type_id)}
          />
          <FieldGroupViewItemStacked
            title={t('common.description')}
            value={transaction.description}
          />
        </FieldGroupRowStacked>

        <FieldGroupRowStacked spacing={2}>
          {transaction.token_id ? (
            <FieldGroupViewItemStacked
              title={t('common.title')}
              value={(transaction.token as { title?: string })?.title}
            />
          ) : null}
          <FieldGroupViewItemStacked
            title={t('common.transaction-sec-code')}
            value={transaction.ach_sec_code || 'PPD'}
          />
        </FieldGroupRowStacked>

        <PageLayoutDivider />

        <FieldGroupRowStacked spacing={2}>
          <FieldGroupViewItemStacked
            title={t('common.address')}
            value={transaction.billing_address?.street}
          />
          <FieldGroupViewItemStacked
            title={t('common.zip-code')}
            value={transaction.billing_address?.postal_code}
          />
        </FieldGroupRowStacked>

        <FieldGroupRowStacked spacing={2}>
          <FieldGroupViewItemStacked
            title={t('common.country')}
            value={transaction.billing_address?.country}
          />
          <FieldGroupViewItemStacked
            title={t('common.phone')}
            value={transaction.billing_address?.phone}
          />
        </FieldGroupRowStacked>

        <PageLayoutDivider />

        <Grid item xs={12} sm={8} className={classes.fixCheckboxHover}>
          <Controller
            name="customer_consent"
            control={formMethods.control}
            render={({ field }) => {
              const handleCheckboxChange = (
                event: React.ChangeEvent<HTMLInputElement>
              ) => {
                field.onChange(event)

                if (!event.target.checked) {
                  formMethods.setValue('return_fee', null, {
                    shouldValidate: false,
                  })
                  formMethods.clearErrors('return_fee')
                }
              }

              return (
                <Checkbox
                  label={t('common.customer-consent')}
                  checked={!!field.value}
                  onChange={handleCheckboxChange}
                  labelStyle={{ marginLeft: undefined }}
                />
              )
            }}
          />
        </Grid>

        {isConsent && (
          <Box mt={1}>
            <span className={classes.itemLabel}>
              {t('common.ach-return-fee')}
            </span>
            <Controller
              name="return_fee"
              control={formMethods.control}
              render={({ field, fieldState }) => (
                <Input
                  {...field}
                  placeholder="$0.00"
                  inputProps={{ inputMode: 'numeric', pattern: '[0-9.]*' }}
                  value={field.value}
                  helperText={
                    fieldState.error?.message ||
                    t('common.return-fee-can-be-up-to-35')
                  }
                  error={!!fieldState.error}
                />
              )}
            />
          </Box>
        )}

        <Box className={classes.totalAmountContainer}>
          <span className={classes.totalAmountLabel}>
            {t('common.total-amount')}
          </span>
          <span className={classes.totalAmountValue} data-testid="total-amount">
            {currency(totalInCents, true)}
          </span>
        </Box>
      </>
    )
  }

  return (
    <FormProvider {...formMethods}>
      <ActionModal
        open={open}
        onClose={handleClose}
        title={modalTitle}
        titleNode={
          <Box display="flex" alignItems="center" gap={1}>
            <span>{modalTitle}</span>
            <Tooltip classes={{ popper: classes.tooltip }} title={nachaTooltip}>
              <IconButton size="small">
                <InfoOutlinedIcon fontSize="inherit" />
              </IconButton>
            </Tooltip>
          </Box>
        }
        isShowTitleDivider
        buttons={[
          {
            label: t('common.cancel'),
            color: 'secondary',
            onClick: handleClose,
          },
          {
            label: t('common.retry-payment'),
            color: 'primary',
            onClick: formMethods.handleSubmit(onSubmit),
            isLoading: isLoading,
            disabled: isLoading,
          },
        ]}
        className={classes.modal}
        isScrollableContent
      >
        {transaction && renderLayout()}
      </ActionModal>
    </FormProvider>
  )
}

export default ACHRetryModal
