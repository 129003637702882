import { ApiService, App } from '../commons'
import { expandQuery } from '../hooks/expandQuery'
import { mapResult } from '../hooks/mapResult'
import { User } from '../schemas/types'

export type ApiKeyResponse = {
  user_api_key: string
}

export class UsersService extends ApiService<User> {
  async authorize() {
    return this.apiRequest<User>({
      url: `authorize`,
      method: 'GET',
    })
  }

  async apiKey(userId: string) {
    const response = await this.apiRequest<ApiKeyResponse>({
      body: {},
      headers: {
        'Content-Type': 'application/json',
      },
      url: `${userId}/api-key`,
      method: 'POST',
    })

    return response
  }

  /**
   * Change password for a user.
   * @param userId - ID of the user.
   * @param password - New password.
   * @param old_password - Old password (optional for updating another user's password).
   * @param terms_condition_code - Terms condition code (optional for updating another user's password).
   */
  async changePassword(
    userId: string,
    password: string,
    old_password?: string | null,
    terms_condition_code?: string | null
  ) {
    const body: Record<string, string | null> = {
      password,
      old_password: old_password ?? null,
      terms_condition_code: terms_condition_code ?? null,
    }

    return this.apiRequest<User>({
      body,
      headers: {
        'Content-Type': 'application/json',
      },
      url: `${userId}/change-password`,
      method: 'PATCH',
    })
  }

  async sendVerification(userId: string) {
    return this.apiRequest<User>({
      body: {},
      headers: {
        'Content-Type': 'application/json',
      },
      url: `${userId}/send-verification`,
      method: 'POST',
    })
  }

  async removeVerification(userId: string) {
    return this.apiRequest<User>({
      body: {},
      headers: {
        'Content-Type': 'application/json',
      },
      url: `${userId}/remove-verification`,
      method: 'DELETE',
    })
  }
}

declare module '../commons' {
  interface ServiceTypes {
    users: UsersService
  }
}

export const users = (app: App) => {
  const connection = app.get('connection')

  app.use('users', new UsersService(connection.service('users'), app))

  app.service('users').hooks({
    around: {
      all: [mapResult(), expandQuery('location_users', 'auth_roles')],
    },
  })
}
