import { tss } from 'tss-react/mui'

import { Grid } from '@mui/material'

import Button from '../buttons/Button'
import Input, { InputProps } from '../form-fields/inputs/Input'
import { Typeahead, TypeaheadProps } from '../form-fields/typeahead/Typeahead'
import TagList from '../merchant/tag-list/TagList'

const useStyles = tss.withName('ListTypeahead').create(() => ({
  buttonContainer: {
    width: '100%',
    position: 'absolute',
    bottom: '0',
  },
  button: {
    height: '44px',
  },
}))

interface ListTypeaheadProps<T> {
  typeaheadProps?: TypeaheadProps<T>
  inputProps?: InputProps
  buttonLabel: string
  guidingId?: string
  onButtonClick: () => void
  isButtonLoading?: boolean
  isButtonEnabled?: boolean
  tagsItems: T[]
  tagsNameSelector: (value: T) => string
  onDeleteTag?: (value: T) => void
  tagBackgroundColor?: string
  tagTextColor?: string
}

export const ListTypeahead = <T extends unknown>({
  buttonLabel,
  guidingId,
  onButtonClick,
  isButtonLoading = false,
  isButtonEnabled = true,
  typeaheadProps,
  inputProps,
  tagsItems,
  tagsNameSelector,
  onDeleteTag,
  tagTextColor,
  tagBackgroundColor,
}: ListTypeaheadProps<T>) => {
  const { classes } = useStyles()

  return (
    <>
      <Grid container marginBottom="24px" spacing={2} width="98%">
        <Grid item xs={9}>
          {typeaheadProps ? (
            <Typeahead {...typeaheadProps} />
          ) : (
            <>
              <Input {...inputProps} />
            </>
          )}
        </Grid>
        <Grid item xs={3} position="relative">
          <Button
            color="secondary"
            label={buttonLabel}
            onClick={onButtonClick}
            containerClassName={classes.buttonContainer}
            isLoading={isButtonLoading}
            disabled={!isButtonEnabled}
            className={classes.button}
            guidingId={`${guidingId}-button`}
          />
        </Grid>
      </Grid>
      <TagList<T>
        items={tagsItems}
        nameSelector={tagsNameSelector}
        onDelete={onDeleteTag}
        backgroundColor={tagBackgroundColor}
        textColor={tagTextColor}
        guidingId={`${guidingId}-taglist`}
      />
    </>
  )
}
