import React from 'react'
import { tss } from 'tss-react/mui'

import { Grid } from '@mui/material'

const useStyles = tss
  .withName('PageLayoutContainerSideBlocks')
  .create(({ theme }) => ({
    root: {
      [theme.breakpoints.down('lg')]: {
        marginTop: '24px',
      },
      [theme.breakpoints.up('lg')]: {
        marginTop: '0',
      },
      padding: '0 !important',
    },
  }))

export interface PageLayoutContainerSideBlocksProps {
  testID?: string
}

export const PageLayoutContainerSideBlocks: React.FC<
  PageLayoutContainerSideBlocksProps
> = ({ children, testID = 'page-layout-container-side' }) => {
  const { classes } = useStyles()

  return (
    <Grid
      item
      sm={12}
      lg={4}
      xl={3}
      data-testid={testID}
      className={classes.root}
    >
      {children}
    </Grid>
  )
}
