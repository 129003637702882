import React, { ChangeEvent, useEffect, useState } from 'react'
import { tss } from 'tss-react/mui'

import { Grid, Typography } from '@mui/material'

const useStyles = tss
  .withName('FieldGroupViewItemStacked')
  .create(({ theme }) => ({
    title: {
      color: theme.palette['neutral-600'],
      fontFamily: 'Inter',
      fontSize: '14px',
      fontWeight: '400',
      lineHeight: '20px',
    },
    value: {
      color: theme.palette['neutral-900'],
      fontFamily: 'Inter',
      fontSize: '14px',
      fontWeight: '500',
      lineHeight: '20px',
      wordBreak: 'break-word',
    },
  }))

const EMPTY_FIELD_PLACEHOLDER = '-'

export interface FieldGroupViewItemStackedProps {
  title: string
  titleTestID?: string
  value?: string
  valueTestID?: string
  isFullWidth?: boolean
  isValueAlignRight?: boolean
  direction?: 'row' | 'column'
}

export const FieldGroupViewItemStacked: React.FC<
  FieldGroupViewItemStackedProps
> = ({
  title,
  titleTestID = 'field-group-item-view-title',
  value,
  valueTestID = 'field-group-item-view-value',
  isFullWidth = false,
  isValueAlignRight = false,
  direction = 'column',
}) => {
  const { classes } = useStyles()

  return (
    <Grid item xs={12} sm={isFullWidth ? 12 : 6}>
      <Grid container direction={direction}>
        <Grid item sm={12} md={6}>
          <Typography
            variant="body1"
            className={classes.title}
            data-testid={titleTestID}
          >
            {title}
          </Typography>
        </Grid>
        <Grid item sm={12} md={6}>
          <Typography
            className={classes.value}
            data-testid={valueTestID}
            variant="body1"
            textAlign={isValueAlignRight ? 'right' : 'left'}
          >
            {value ? value : EMPTY_FIELD_PLACEHOLDER}
          </Typography>
        </Grid>
      </Grid>
    </Grid>
  )
}
