import React, { forwardRef, useMemo } from 'react'
import { tss } from 'tss-react/mui'

import { Box, TextField, Typography, MenuItem, Select } from '@mui/material'

const useStyles = tss.withName('InputWithSelect').create(({ theme }) => ({
  label: {
    color: `${theme?.palette['neutral-700']} !important`,
    fontFamily: 'Inter !important',
    fontSize: '14px !important',
    fontWeight: '500 !important',
    lineHeight: '20px !important',
  },
  required: {
    color: `${theme?.palette['negative']} !important`,
    fontFamily: 'Inter !important',
    fontSize: '14px !important',
    fontWeight: '500 !important',
    lineHeight: '20px !important',
    marginLeft: '3px !important',
  },
  input: {
    borderRadius: '6px',
    '& input': {
      padding: '12px !important',
      fontFamily: 'Inter !important',
      fontSize: '14px !important',
      fontWeight: '500 !important',
      lineHeight: '20px !important',
    },
    '& label.Mui-focused': {
      color: theme.palette['neutral-700'],
    },
    '& .MuiInput-underline:after': {
      borderBottomColor: theme.palette['neutral-300'],
      borderWidth: '1px',
    },
  },
  helperText: {
    marginLeft: '0 !important',
    fontFamily: 'Inter !important',
    fontSize: '12px !important',
    fontWeight: '500 !important',
    lineHeight: '16px !important',
    color: `${theme?.palette['neutral-500']} !important`,
  },
  helperTextError: {
    color: `${theme?.palette['negative']} !important`,
  },
  select: {
    background: 'none',
    position: 'absolute',
    zIndex: 10,
    marginTop: '6px',
    right: '0',
    overflow: 'hidden',
    borderRadius: '4px',
    borderBottom: 'none',
    marginRight: '8px',
    border: 'none',
    '&:hover': {
      cursor: 'pointer',
    },
  },
  disabledSelect: {
    background: 'none',
    position: 'absolute',
    right: '0',
    margin: '10px 10px 0px 0px',
    color: `${theme?.palette['disabled']} !important`,
  },
}))

export interface InputProps {
  label: string
  value?: string | number
  selectContent: Array<{ label: string; value: string }>
  selectContentValue: string
  setContentValue: any
  placeholder: string
  onChange: React.ChangeEventHandler<HTMLTextAreaElement | HTMLInputElement>
  onBlur?: React.FocusEventHandler<HTMLTextAreaElement | HTMLInputElement>
  type?: string
  error?: boolean
  helperText?: string
  required?: boolean
  disabled?: boolean
  icon?: JSX.Element
  onClickIcon?: React.MouseEventHandler<HTMLButtonElement>
  style?: React.CSSProperties
  testId?: string
  inputRef?: React.Ref<any>
  guidingId?: string
}

const InputWithSelect = forwardRef<unknown, InputProps>(
  (
    {
      label,
      value,
      selectContent,
      placeholder,
      onChange,
      onBlur,
      type,
      error = false,
      helperText,
      required = false,
      disabled = false,
      selectContentValue,
      setContentValue,
      style,
      testId,
      guidingId,
    },
    ref
  ) => {
    const { classes } = useStyles()

    const disabledValueDisplay = useMemo(() => {
      if (!disabled) return
      let selectedValue = ''
      selectContent.map((option) => {
        if (selectContentValue === option.value) {
          selectedValue = option.label
        }
      })
      return selectedValue
    }, [selectContent, selectContentValue, disabled])

    return (
      <Box sx={style} data-guiding-id={`${guidingId}-container`}>
        <Box sx={{ display: 'flex', marginBottom: '.6em' }}>
          <Typography className={classes.label}>{label}</Typography>
          {required && (
            <Typography
              className={classes.required}
              data-testid={`${testId}-required`}
            >
              *
            </Typography>
          )}
        </Box>
        <div style={{ display: 'flex', width: '100%', position: 'relative' }}>
          <TextField
            inputRef={ref}
            value={value}
            placeholder={placeholder}
            onChange={onChange}
            onBlur={onBlur}
            type={type}
            error={error}
            helperText={helperText}
            disabled={disabled}
            className={classes.input}
            fullWidth
            variant="outlined"
            size="small"
            inputProps={{
              'data-testid': testId,
              'data-guiding-id': `${guidingId}-input`,
              min: 1,
            }}
            FormHelperTextProps={{
              id: `${testId}-helper`,
              className: `${classes.helperText} ${
                error ? classes.helperTextError : ''
              }`,
            }}
          ></TextField>
          {disabled ? (
            <Typography className={classes.disabledSelect}>
              {disabledValueDisplay}
            </Typography>
          ) : (
            <Select
              variant="standard"
              disableUnderline={true}
              className={classes.select}
              value={selectContentValue}
              onChange={(value) => {
                setContentValue(value.target.value)
              }}
              disabled={disabled}
              data-guiding-id={`${guidingId}-select`}
            >
              {selectContent.map((option) => (
                <MenuItem key={option.value} value={option.value}>
                  {option.label}
                </MenuItem>
              ))}
            </Select>
          )}
        </div>
      </Box>
    )
  }
)

export default InputWithSelect
