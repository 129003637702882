import { FC, forwardRef } from 'react'
import MaskedInputComponent, { MaskedInputProps } from 'react-text-mask'
import { tss } from 'tss-react/mui'

import { Box, Typography } from '@mui/material'

import { useInputStyles, InputProps, Input } from '@shared/components'

type StylesParams = {
  error: boolean
  disabled: boolean
  noHoverBorder: boolean
}

const useStyles = tss
  .withName('MaskedInput')
  .withParams<StylesParams>()
  .create(({ theme, error, disabled, noHoverBorder }) => ({
    inputContainer: {
      position: 'relative',
      opacity: disabled ? '.5' : undefined,
    },
    // These styles are took and adapted
    // from form-fields/inputs/Input.tsx in shared/components
    input: {
      width: '100%',
      borderRadius: '4px',
      padding: '12px',
      fontFamily: 'Inter',
      fontSize: '14px',
      fontWeight: '500',
      '::placeholder': { fontWeight: '300' },
      lineHeight: '20px',
      border: error
        ? `1px solid ${theme.palette['negative']}`
        : `1px solid #c5c4c5`,
      ':hover': {
        border: !!error
          ? `1px solid ${theme.palette['negative']}`
          : noHoverBorder
          ? undefined
          : '1px solid black',
      },
      ':focus': {
        border: noHoverBorder
          ? '1px solid #9e9e9e'
          : error
          ? `1px solid ${theme.palette['negative']}`
          : '2px solid #1c74d3',
      },
    },
    iconContainer: {
      position: 'absolute',
      top: '50%',
      right: '14px',
      transform: 'translate(0, -50%)',
    },
    startAdornment: {
      position: 'absolute',
      top: '50%',
      left: '14px',
      transform: 'translate(0, -50%)',
    },
    helperText: {
      margin: '.3em 0 .5em 0',
    },
  }))

export interface IMaskedInputProps
  extends MaskedInputProps,
    Pick<
      InputProps,
      | 'label'
      | 'icon'
      | 'required'
      | 'helperText'
      | 'noHoverBorder'
      | 'error'
      | 'testId'
      | 'guidingId'
    > {
  startAdornment?: React.ReactNode
}

export const MaskedInput: FC<IMaskedInputProps> = forwardRef(
  (
    {
      id,
      label,
      icon,
      disabled,
      required,
      helperText,
      error,
      guide = false,
      className,
      noHoverBorder,
      testId,
      startAdornment,
      guidingId,
      ...props
    },
    ref
  ) => {
    const { classes } = useStyles({ error, disabled, noHoverBorder })
    const { classes: inputClasses, cx } = useInputStyles({ noHoverBorder })

    return (
      <Box ref={ref} data-guiding-id={`${guidingId}-container`}>
        <Input.Label text={label} required={required} testId={testId} />

        <Box className={classes.inputContainer}>
          {startAdornment && (
            <Box className={classes.startAdornment}>{startAdornment}</Box>
          )}
          <MaskedInputComponent
            {...props}
            className={cx(classes.input, className)}
            disabled={disabled}
            guide={guide}
            data-testid={testId}
            data-guiding-id={guidingId}
          />

          <Box className={classes.iconContainer}>
            <Input.Icon id={id} icon={icon} />
          </Box>
        </Box>

        {!!helperText && (
          <Typography
            id={`${testId}-helper`}
            className={cx(
              inputClasses.helperText,
              error ? inputClasses.helperTextError : '',
              classes.helperText
            )}
            variant="body2"
          >
            {helperText}
          </Typography>
        )}
      </Box>
    )
  }
)
