import { ChangeEvent, PropsWithChildren } from 'react'
import { ControllerRenderProps } from 'react-hook-form'
import { tss } from 'tss-react/mui'

import { Box, Typography } from '@mui/material'

import Checkbox, { CheckboxProps } from './Checkbox'
import Input, { useInputStyles } from '../inputs/Input'

export type CheckboxGroupItem<T extends string> = {
  key: string
  value: T
} & Pick<
  CheckboxProps,
  'label' | 'description' | 'disabled' | 'testId' | 'guidingId'
>

interface CheckboxGroupProps<T extends string> {
  field: ControllerRenderProps<any, any>
  label: string
  items: CheckboxGroupItem<T>[]
  onChange: (checkedItems: T[]) => void
  testId?: string
  infoText?: string
  helperText?: string
  error?: boolean
  required?: boolean
  containerClassName?: string
  checkboxGroupClassName?: string
  checkboxStyle?: React.CSSProperties
}

const useCheckboxGroupStyles = tss
  .withName('CheckboxGroup')
  .create(({ theme }) => ({
    checkboxGroup: {
      display: 'grid',
      gridTemplateColumns: 'repeat(2, 1fr)',
      gap: theme.spacing(1),
    },
    checkboxWrapper: {
      border: `1px solid ${theme.palette['neutral-300']}`,
      borderRadius: '4px',
      display: 'flex',
      alignItems: 'center',
      cursor: 'pointer',
      height: '48px',

      '&:hover': {
        backgroundColor: theme.palette['neutral-50'],
      },
    },
    selected: {
      border: `1.5px solid ${theme.palette['power-orange']} !important`,
      backgroundColor: `${theme.palette['neutral-50']} !important`,
    },
    disablePointerEvents: {
      pointerEvents: 'none',
    },
  }))

export function CheckboxGroup<T extends string>({
  field,
  label,
  items,
  onChange,
  containerClassName,
  checkboxGroupClassName,
  checkboxStyle,
  infoText,
  helperText,
  error,
  testId,
  required,
}: PropsWithChildren<CheckboxGroupProps<T>>) {
  const { classes, cx } = useCheckboxGroupStyles()
  const { classes: inputClasses, cx: inputClassesCx } = useInputStyles()

  const handleToggle = (value: T) => {
    const isSelected = field.value?.includes(value) ?? false

    let checkedItems: T[] = []
    if (!isSelected) {
      checkedItems = field.value ? [...field.value, value] : [value]
    } else {
      checkedItems = field.value?.filter(
        (itemValue: T) => itemValue !== value
      ) as T[]
    }

    onChange(checkedItems)
    field.onChange(checkedItems)
  }

  return (
    <Box className={containerClassName} data-testid={testId}>
      <Input.Label text={label} required={required} infoText={infoText} />

      <Box className={cx(classes.checkboxGroup, checkboxGroupClassName)}>
        {items.map(({ value, ...checkboxProps }) => {
          const isSelected = field.value?.includes(value) ?? false

          return (
            <Box
              key={value}
              className={cx(
                classes.checkboxWrapper,
                isSelected && classes.selected
              )}
              style={checkboxStyle}
              onClick={() => handleToggle(value)}
            >
              <div className={classes.disablePointerEvents}>
                <Checkbox
                  {...checkboxProps}
                  checked={isSelected}
                  onChange={(event: ChangeEvent<HTMLInputElement>) => {
                    event.stopPropagation()
                  }}
                />
              </div>
            </Box>
          )
        })}
      </Box>

      {helperText && (
        <Typography
          variant="body2"
          className={inputClassesCx(
            inputClasses.helperText,
            error ? inputClasses.helperTextError : undefined
          )}
        >
          {helperText}
        </Typography>
      )}
    </Box>
  )
}
