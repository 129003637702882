import { DateTime } from 'luxon'
import React, { forwardRef, useState } from 'react'
import MaskedInput from 'react-text-mask'
import { tss } from 'tss-react/mui'

import { Box, Popover } from '@mui/material'
import { AdapterLuxon } from '@mui/x-date-pickers/AdapterLuxon'
import {
  DateCalendar,
  DateCalendarProps,
} from '@mui/x-date-pickers/DateCalendar'
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider'
import { PickerValidDate } from '@mui/x-date-pickers/models'

import { Input } from '@shared/components'
import { dateMask, formatRawDate } from '@shared/utils'

import Calendar from '../../../icons/Calendar'

const useStyles = tss.withName('DatePicker').create(({ theme }) => ({
  disabledInputDate: {
    '&:hover': {
      cursor: 'default',
    },
  },
}))

export interface DatePickerProps
  extends Omit<DateCalendarProps<PickerValidDate>, 'onChange' | 'value'> {
  label: string
  value?: string
  onChange: React.ChangeEventHandler<HTMLTextAreaElement | HTMLInputElement>
  onBlur?: React.FocusEventHandler<HTMLTextAreaElement | HTMLInputElement>
  error?: boolean
  helperText?: string
  required?: boolean
  disabled?: boolean
  style?: React.CSSProperties
  testId?: string
  disablePast?: boolean
  disableToday?: boolean
  inputRef?: React.Ref<any>
  guidingId?: string
  useValueAsInitialDate?: boolean
}

const DatePicker = forwardRef<unknown, DatePickerProps>(
  (
    {
      label,
      value,
      onChange,
      onBlur,
      error = false,
      helperText,
      required = false,
      disabled = false,
      style,
      testId,
      disablePast = false,
      guidingId,
      useValueAsInitialDate,
      disableToday = false,
      ...props
    },
    ref
  ) => {
    if (value === '' || value === '-') {
      value = null
    }
    const [openCalendar, setOpenCalendar] = useState(false)
    const [dateForPicker, setDateForPicker] = useState(
      DateTime.fromISO(
        new Date(
          value?.toString()?.length === 9 ? value : new Date()
        ).toISOString()
      )
    )

    const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null)

    const { classes } = useStyles()

    const handleCalendarClick = (e) => {
      if (disabled) return
      setAnchorEl(e.currentTarget as HTMLElement)
      setOpenCalendar(true)
    }

    const handleCalendarChange = (value) => {
      const dateFormatted = formatRawDate(value.ts, 'MM/dd/yyyy')
      setDateForPicker(value)
      onChange({
        target: {
          value: dateFormatted,
        },
      } as React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>)
    }

    const parsedDate = value ? DateTime.fromFormat(value, 'MM/dd/yyyy') : null
    const initialCalendarDate =
      useValueAsInitialDate && parsedDate && parsedDate.isValid
        ? parsedDate
        : undefined

    return (
      <Box data-guiding-id={`${guidingId}-container`}>
        <Popover
          open={openCalendar}
          anchorEl={anchorEl}
          onClose={() => setOpenCalendar(false)}
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'left',
          }}
          transformOrigin={{
            vertical: 'top',
            horizontal: 'right',
          }}
        >
          <LocalizationProvider dateAdapter={AdapterLuxon}>
            <DateCalendar
              {...props}
              disablePast={disablePast}
              onChange={handleCalendarChange}
              disabled={disabled}
              defaultValue={initialCalendarDate}
              value={initialCalendarDate || dateForPicker}
              minDate={
                disableToday
                  ? DateTime.now().plus({ days: 1 })
                  : DateTime.fromISO('1900-01-01')
              }
            />
          </LocalizationProvider>
        </Popover>

        <Input
          ref={ref}
          label={label}
          value={value}
          mask={DateMaskInput}
          onChange={onChange}
          onBlur={onBlur}
          error={error}
          helperText={helperText}
          icon={{
            component: (
              <Calendar
                className={disabled ? classes.disabledInputDate : ''}
                data-guiding-id={`${guidingId}-icon`}
              />
            ),
            onClick: handleCalendarClick,
          }}
          required={required}
          disabled={disabled}
          style={style}
          testId={testId}
          guidingId={guidingId}
        />
      </Box>
    )
  }
)

const DateMaskInput = React.forwardRef((props, ref) => {
  const { ...other } = props
  return <MaskedInput {...other} mask={dateMask} placeholder="MM/DD/YYYY" />
})

export default DatePicker
