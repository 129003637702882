export enum EnumServiceName {
  Contacts = 'contacts',
  RecurringBillings = 'recurring-billings',
  Batches = 'batches',
  BatchesDetails = 'batches-details',
  ChargebacksReports = 'chargebacks-reports',
  DepositsReports = 'deposits-reports',
  SettledTransactionsReportsAch = 'settled-transactions-reports-ach',
  SettledTransactionsReportsCc = 'settled-transactions-reports-cc',
  GatewayTransactionsReports = 'transactions',
  Users = 'users',
  RecurringBillingDeclinesReports = 'recurring-billing-declines-reports',
  QuickInvoices = 'quick-invoices',
  AchRejectsReports = 'ach-rejects-reports',
  TokenImports = 'token-imports',
  TokenImportMappings = 'token-import-mappings',
  Merchants = 'merchants',
  Applications = 'applications',
  Commissions = 'commissions',
  ApplicationTemplates = 'templates',
  Paylinks = 'paylinks',
  Statements = 'statements',
}
