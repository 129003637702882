import React from 'react'
import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router-dom'
import { tss } from 'tss-react/mui'

import {
  Box,
  Button,
  List,
  ListItem,
  ListItemText,
  Typography,
} from '@mui/material'

import { Recurring } from '@shared/api/src'
import { RecurringStatus } from '@shared/components'
import AppTheme from '@shared/design'
import {
  useFormatRecurringType,
  useFormatIntervals,
  formatDate,
} from '@shared/utils'

interface RecurringBillingInfoCardProps {
  recurringInfo: Recurring
  tz: string
}
const useStyles = tss.withName('RecurringBillingInfoCard').create(() => ({
  listItem: {
    display: 'flex',
    justifyContent: 'space-between',
    width: '100%',
  },
  listItemText: {
    flex: '1 1 auto',
    '& .MuiListItemText-primary': {
      textAlign: 'left',
    },
    '& .MuiListItemText-secondary': {
      textAlign: 'right',
    },
  },
  label: {
    fontSize: '14px',
    color: '#757575',
  },
  titleContent: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    marginBottom: '10px',
  },
  title: {
    fontWeight: 600,
    fontSize: '1rem',
    color: '#4a5568',
  },
  viewButton: {
    textTransform: 'none',
    boxShadow: 'none',
    background: 'white',
    border: '1px solid #E0E0E0',
    borderRadius: '4px',
    padding: '3px 10px',
    color: '#4a5568',
    '&:hover': {
      background: '#E0E0E0',
      borderColor: '#E0E0E0',
    },
  },
}))

export const RecurringBillingInfoCard: React.FC<
  RecurringBillingInfoCardProps
> = ({ recurringInfo, tz }) => {
  const { classes } = useStyles(AppTheme)
  const { t } = useTranslation()
  const navigate = useNavigate()

  const { status, recurring_type_id, interval, interval_type, next_run_date } =
    recurringInfo

  const billingTypeFormatted = useFormatRecurringType(recurring_type_id)
  const billingFrequency = useFormatIntervals(
    interval ?? 0,
    interval_type ?? 'd'
  )
  const billingNextRunDateFormatted =
    next_run_date && next_run_date !== '0000-00-00'
      ? formatDate(next_run_date)
      : ''

  const itemData = [
    {
      label: t('common.status'),
      info: <RecurringStatus statusCode={status} />,
    },
    { label: t('common.type'), info: billingTypeFormatted },
    { label: t('common.every'), info: billingFrequency },
    { label: t('common.date-next-run'), info: billingNextRunDateFormatted },
  ]

  const urlView = `/merchant/gateway/recurring-billing/${recurringInfo.id}/view`
  const showButtonViewRecurringBilling = urlView !== window.location.pathname
  const onViewDetails = () => {
    navigate(urlView)
  }

  return (
    <Box
      display="flex"
      flexDirection="column"
      width="100%"
      bgcolor="rgb(243, 244, 246)"
      border={1}
      borderColor="grey.300"
      borderRadius={2}
      p={2}
      my={2}
    >
      <Box className={classes.titleContent}>
        <Typography variant="h6" className={classes.title}>
          {t('common.recurring-billing')}
        </Typography>
        {showButtonViewRecurringBilling ? (
          <Button
            variant="outlined"
            onClick={onViewDetails}
            className={classes.viewButton}
            data-guiding-id="recurringbilling-infocard-view"
          >
            {t('common.view')}
          </Button>
        ) : null}
      </Box>
      <List disablePadding>
        {itemData.map(({ label, info }, index) => (
          <ListItem key={index} disableGutters className={classes.listItem}>
            <Typography variant="caption" className={classes.label}>
              {label}
            </Typography>
            <ListItemText
              className={classes.listItemText}
              secondary={info}
              secondaryTypographyProps={{
                variant: 'body2',
                color: 'textPrimary',
                component: 'span',
              }}
            />
          </ListItem>
        ))}
      </List>
    </Box>
  )
}

export default RecurringBillingInfoCard
