import { ApiService, App } from '../../commons'
import { expandQuery } from '../../hooks/expandQuery'
import { mapResult } from '../../hooks/mapResult'

export class TransactionAchRetriesService extends ApiService<any> {}

declare module '../../commons' {
  interface ServiceTypes {
    'transaction-ach-retries': TransactionAchRetriesService
  }
}

export const TransactionAchRetries = (app: App) => {
  const connection = app.get('connection')

  app.use(
    'transaction-ach-retries',
    new TransactionAchRetriesService(
      connection.service('transaction-ach-retries'),
      app
    )
  )

  app.service('transaction-ach-retries').hooks({
    around: {
      all: [
        mapResult(),
        expandQuery('return_fee_transaction', 'retry_transaction'),
      ],
    },
  })
}
