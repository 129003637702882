import React from 'react'
import { tss } from 'tss-react/mui'

import ErrorIcon from '@mui/icons-material/Error'
import { Box, Typography, Modal } from '@mui/material'

import { Button } from '@shared/components'
import appTheme from '@shared/design'

interface ActionButton {
  label: string
  onClick: () => void
  style?: string
  show: boolean
  color: 'primary' | 'secondary'
  guidingId?: string
}

interface ErrorModalProps {
  title: string
  open: boolean
  handleClose: () => void
  buttons: ActionButton[]
}

const useStyles = tss.withName('Confirmation').create(({ theme }) => ({
  modal: {
    width: '400px',
    height: 'auto',
    position: 'absolute',
    top: '50%',
    left: '56%',
    transform: 'translate(-50%, -50%)',
    backgroundColor: theme?.palette.background.paper,
    borderRadius: '8px',
    padding: '24px',
    rowGap: '24px',
  },
  dataContainer: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  title: {
    color: theme.palette['neutral-900'],
    fontSize: '24px',
    fontWeight: 500,
    lineHeight: '32px',
    textAlign: 'center',
  },
  icon: {
    display: 'flex',
    padding: '16px',
    alignItems: 'flex-start',
    gap: '10px',
    borderRadius: '100px',
    height: '64px',
    width: '64px',
    backgroundColor: 'var(--red-100, #FEE2E2)',
    marginBottom: '24px',
  },
  buttonsContainer: {
    gap: '24px',
    justifyContent: 'center',
    marginTop: '40px',
  },
}))

const ErrorModal = ({ title, open, handleClose, buttons }: ErrorModalProps) => {
  const { classes } = useStyles()
  return (
    <Modal
      open={open}
      onClose={handleClose}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
      data-testid="modal"
      disableAutoFocus={true}
    >
      <Box className={classes.modal}>
        <Box className={classes.dataContainer}>
          <Box className={classes.icon}>
            <ErrorIcon
              style={{
                color: appTheme.palette.negative,
                height: '32px',
                width: '32px',
              }}
            />
          </Box>
          <Box>
            <Typography className={classes.title}>{title}</Typography>
            <Typography textAlign="center" marginTop="6px">
              Something went wrong while processing your request, try again.
            </Typography>
          </Box>
        </Box>
        <Box className={classes.buttonsContainer}>
          {buttons
            .filter((button) => button.show)
            .map((button, index) => (
              <Button
                key={index}
                label={button.label}
                onClick={button.onClick}
                containerClassName={button.style}
                color={button.color}
                guidingId={button.guidingId}
              />
            ))}
        </Box>
      </Box>
    </Modal>
  )
}

export default ErrorModal
