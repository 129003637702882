import { ApiService, App } from '../../commons'
import { mapResult } from '../../hooks/mapResult'
import { BusinessType } from '../../schemas/types'

// TODO: add responseBusinessTypesCollection to the oas file when available
export class BusinessTypesService extends ApiService<BusinessType> {}

declare module '../../commons' {
  interface ServiceTypes {
    'business-types': BusinessTypesService
  }
}

export const partnerBusinessTypes = (app: App) => {
  const connection = app.get('connection')

  app.use(
    'business-types',
    new BusinessTypesService(connection.service('partner/business-types'), app),
    {}
  )

  app.service('business-types').hooks({
    around: {
      all: [mapResult()],
    },
  })
}
