import React from 'react'
import { useTranslation } from 'react-i18next'
import { tss } from 'tss-react/mui'

import PersonIcon from '@mui/icons-material/PersonOutlined'
import { Box, List, ListItem, ListItemIcon, ListItemText } from '@mui/material'

import { Contact } from '@shared/api/src'
import AppTheme from '@shared/design'
import { EmailIcon, AddressIcon, PhoneIcon } from '@shared/icons'
import { customerAddressToString, formatPhoneNumber } from '@shared/utils'

interface ContactInfoCardProps {
  contact: Contact | null
  hasBackground?: boolean
}

const useStyles = tss.withName('ContactInfoCard').create(({ theme }) => ({
  listItemIcon: {
    minWidth: '20px',
    marginTop: '2px',
    marginRight: '6px',
  },
  listItemText: {
    marginTop: '0',
    marginLeft: '0px',
  },
  background: {
    backgroundColor: 'rgb(243, 244, 246)',
    border: '1px solid rgb(229, 231, 235)',
    borderColor: '#e0e0e0',
    borderRadius: '8px',
    padding: '16px',
  },
}))

export const ContactInfoCard: React.FC<ContactInfoCardProps> = ({
  contact,
  hasBackground = false,
}) => {
  const { classes } = useStyles(AppTheme)
  const { t } = useTranslation()
  const EMPTY_FIELD_PLACEHOLDER = '-'

  const getFullName = () => {
    if (!contact) return EMPTY_FIELD_PLACEHOLDER
    return `${contact.first_name ?? ''} ${contact.last_name}`
  }

  const itemData = [
    {
      label: t('common.name-customer'),
      info: getFullName(),
      Icon: PersonIcon,
    },
    {
      label: t('common.address'),
      info: !!contact?.address
        ? customerAddressToString(contact.address)
        : EMPTY_FIELD_PLACEHOLDER,
      Icon: AddressIcon,
    },
    {
      label: t('common.email'),
      info: !!contact?.email ? contact.email : EMPTY_FIELD_PLACEHOLDER,
      Icon: EmailIcon,
    },
    {
      label: t('common.phone'),
      info: !!contact?.cell_phone
        ? formatPhoneNumber(contact.cell_phone)
        : EMPTY_FIELD_PLACEHOLDER,
      Icon: PhoneIcon,
    },
  ]

  return (
    <Box
      display="flex"
      flexDirection="column"
      width="100%"
      className={hasBackground ? classes.background : ''}
    >
      <List disablePadding>
        {itemData.map(({ label, info, Icon }, index) => (
          <ListItem
            key={index}
            disableGutters
            style={{
              alignItems: 'flex-start',
              margin: '0 0 8px 0',
              padding: 0,
            }}
          >
            <ListItemIcon className={classes.listItemIcon}>
              <Icon
                color="error"
                style={{
                  width: '16px',
                  height: '16px',
                }}
              />
            </ListItemIcon>

            <ListItemText
              className={classes.listItemText}
              primary={label}
              secondary={info}
              primaryTypographyProps={{
                variant: 'body2',
                color: 'textSecondary',
              }}
              secondaryTypographyProps={{
                variant: 'body2',
                color: 'textPrimary',
              }}
            />
          </ListItem>
        ))}
      </List>
    </Box>
  )
}

export default ContactInfoCard
