import { FC, useState } from 'react'
import { tss } from 'tss-react/mui'

import { ChevronRight } from '@mui/icons-material'
import { Box, Button, Popover } from '@mui/material'

import { ThreeDotMenuOption } from './ThreeDotMenu'

interface ThreeDotOptionProps {
  option: ThreeDotMenuOption
}

const useStyles = tss.withName('ThreeDotMenu').create(({ theme }) => ({
  popover: {
    width: '252px',
    padding: '8px',
    display: 'grid',
  },
  button: {
    height: '44px',
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    textAlign: 'left',
    paddingLeft: '16px',
    paddingRight: '16px',
    borderRadius: '6px',
    fontFamily: 'Inter',
    fontSize: '14px',
    fontWeight: '500',
    lineHeight: '20px',
    color: theme.palette['neutral-700'],
    '&:hover': {
      backgroundColor: theme.palette['neutral-100'],
      color: theme.palette['neutral-900'],
    },
  },
  icon: {
    fontSize: '24px !important',
    color: theme.palette['neutral-500'],
  },
}))

export const ThreeDotOption: FC<ThreeDotOptionProps> = ({ option }) => {
  const { classes } = useStyles()
  const [open, setOpen] = useState(false)
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null)

  if (!option.subOptions || option.subOptions.length === 0) {
    return (
      <Button
        className={classes.button}
        onClick={() => {
          setOpen(false)
          option.action()
        }}
        data-guiding-id={option.guidingId}
        endIcon={
          option.showChevron && (
            <ChevronRight data-testid="option-icon" className={classes.icon} />
          )
        }
      >
        {option.label}
      </Button>
    )
  }

  return (
    <>
      <Popover
        open={open}
        anchorEl={anchorEl}
        onClose={() => setOpen(false)}
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
        transformOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
      >
        <Box className={classes.popover}>
          {option.subOptions
            .filter((subOption) => subOption.enabled)
            .map((subOption, index) => (
              <Button
                key={index}
                className={classes.button}
                onClick={() => {
                  setOpen(false)
                  subOption.action()
                }}
                data-guiding-id={`${option.guidingId}-${
                  subOption.guidingId || index
                }`}
              >
                {subOption.label}
              </Button>
            ))}
        </Box>
      </Popover>

      <Button
        className={classes.button}
        endIcon={
          <ChevronRight data-testid="option-icon" className={classes.icon} />
        }
        onClick={(e) => {
          setAnchorEl(e.currentTarget as HTMLElement)
          setOpen(true)
        }}
        data-guiding-id={option.guidingId}
      >
        {option.label}
      </Button>
    </>
  )
}
