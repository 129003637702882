import { ApiService, App } from '../../commons'
import { mapResult } from '../../hooks/mapResult'
import { Office } from '../../schemas/types'

export class OfficesService extends ApiService<Office> {}
declare module '../../commons' {
  interface ServiceTypes {
    offices: OfficesService
  }
}
export const partnerPortalOffices = (app: App) => {
  const connection = app.get('connection')
  app.use(
    'offices',
    new OfficesService(connection.service('partner/offices'), app)
  )
  app.service('offices').hooks({
    around: {
      all: [mapResult()],
    },
  })
}
