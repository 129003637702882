import { debounce } from 'lodash'
import { FC, useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { tss } from 'tss-react/mui'

import CloseIcon from '@mui/icons-material/Close'
import {
  Box,
  Dialog,
  DialogContent,
  DialogTitle,
  Divider,
  IconButton,
  TextField,
  Typography,
} from '@mui/material'

import { api, Location } from '@shared/api'
import { useLocations } from '@shared/hooks'
import { SearchIcon } from '@shared/icons'

import { LocationOption } from './LocationOption'
import { EmptyFilterPlaceholder } from '../feedback/empty-filter-placeholder/EmptyFilterPlaceholder'
import Loading from '../loading/Loading'

const useStyles = tss.withName('LocationOption').create(({ theme }) => ({
  root: { height: 'auto' },
  paper: {
    position: 'absolute',
    top: '70px',
    left: '50%',
    transform: `translate(-50%, 0)`,
    width: '750px',
    maxWidth: '750px',
    borderRadius: '6px',
  },
  container: { padding: '20px' },
  titleContainer: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
  },
  contentContainer: {
    padding: 0,
    margin: '24px 0 24px 0',
    maxHeight: '468px',
  },
  title: {
    padding: 0,
    fontFamily: 'Inter',
    fontSize: '18px',
    fontWeight: '600',
    lineHeight: '28px',
    color: theme.palette['neutral-900'],
  },
  labelResults: {
    color: theme.palette['neutral-700'],
    fontFamily: 'Inter',
    fontSize: '14px',
    fontWeight: '600',
    lineHeight: '18px',
  },
}))

interface SearchLocationProps {
  open: boolean
  onClose: () => void
}

export const SearchLocation: FC<SearchLocationProps> = ({ open, onClose }) => {
  const { classes } = useStyles()

  const { t } = useTranslation()
  const { setSelectedLocation, selectedLocation } = useLocations()

  const [searchText, setSearchText] = useState('')
  const [markText, setMarkText] = useState('')

  const [searchLocations, setSearchLocations] = useState<Location[]>([])
  const [defaultLocations, setDefaultLocations] = useState<Location[]>([])
  const [totalResults, setTotalResults] = useState(0)

  const [isLoading, setIsLoading] = useState(false)

  const dispatchLocationChangedEvent = (location) => {
    const event = new CustomEvent('FTP_NAVIGATOR_LOCATION_CHANGED', {
      detail: {
        location: location,
      },
    })
    window.dispatchEvent(event)
  }

  const getLocations = async () => {
    try {
      setIsLoading(true)
      const locations = await api.service('location-searches').find({
        query: {
          keyword: searchText ? JSON.stringify(searchText) : undefined,
          relationship: searchText ? 'all' : 'direct',
        },
        paginate: true,
      })

      const results = locations.list.sort((a, b) =>
        a.name.localeCompare(b.name)
      )

      if (!searchText) {
        setDefaultLocations(results)
      }

      setSearchLocations(results)
      setTotalResults(locations.pagination.total_count)
      setMarkText(searchText)
    } catch (error) {
      console.error('Error', error)
    } finally {
      setIsLoading(false)
    }
  }

  useEffect(() => {
    getLocations()
  }, [])

  const resetLocations = () => {
    if (
      !selectedLocation ||
      Object.keys(selectedLocation).length === 0 ||
      defaultLocations.length === 0
    ) {
      return
    }

    const locations = defaultLocations
      .filter((location) => location.id !== selectedLocation.id)
      .sort((a, b) => a.name.localeCompare(b.name))

    locations.unshift(selectedLocation)

    setSearchLocations(locations)
  }

  useEffect(() => {
    resetLocations()
  }, [selectedLocation, defaultLocations])

  const debounceLocations = debounce(getLocations, 500)

  useEffect(() => {
    if (!searchText) {
      setMarkText('')
      resetLocations()
      return
    }

    setIsLoading(true)
    debounceLocations()

    return () => {
      debounceLocations.cancel()
      setIsLoading(false)
    }
  }, [searchText])

  const handleClose = () => {
    setSearchText('')
    resetLocations()
    onClose()
  }

  const onLocationSelect = (location: Location) => {
    setSelectedLocation(location)
    dispatchLocationChangedEvent(location)
    handleClose()
  }

  return (
    <Dialog
      open={open}
      onClose={handleClose}
      classes={{ container: classes.root, paper: classes.paper }}
      data-testid="search-location-modal"
    >
      <Box className={classes.container}>
        <Box className={classes.titleContainer}>
          <DialogTitle
            className={classes.title}
            data-testid="search-location-title"
          >
            {t('common.select-a-location')}
          </DialogTitle>

          <IconButton
            aria-label="close"
            onClick={handleClose}
            data-guiding-id="nav-location-search-close"
          >
            <CloseIcon />
          </IconButton>
        </Box>

        <Divider
          style={{
            margin: '16px -20px',
          }}
        />

        <TextField
          value={searchText}
          onChange={(e) => setSearchText(e.target.value)}
          style={{
            width: '100%',
            marginBottom: '16px',
          }}
          placeholder={t('common.search-by-location')}
          variant="outlined"
          inputProps={{
            'data-testid': 'search-location-input',
            'data-guiding-id': 'nav-location-search-input',
          }}
          InputProps={{
            startAdornment: (
              <SearchIcon
                sx={{
                  marginRight: '8px',
                }}
              />
            ),
          }}
          autoFocus
        />

        {searchText.length > 0 && !isLoading && (
          <Typography className={classes.labelResults}>
            {searchLocations.length < totalResults
              ? t('common.your-search-found-location-out-of', {
                  count: searchLocations.length,
                  total: totalResults,
                })
              : t('common.your-search-found-locations', {
                  count: searchLocations.length,
                })}
          </Typography>
        )}

        <DialogContent
          className={classes.contentContainer}
          data-testid="search-location-content"
        >
          {isLoading ? (
            <Loading
              style={{
                maxHeight: '200px',
              }}
            />
          ) : (
            <>
              {searchLocations.length > 0 ? (
                searchLocations.map((location, index) => (
                  <LocationOption
                    key={location.id}
                    location={location}
                    onSelect={onLocationSelect}
                    matchText={markText}
                    guidingId={`nav-location-search-option-${index}`}
                  />
                ))
              ) : (
                <EmptyFilterPlaceholder
                  title={t('common.not-available-results')}
                  label={t('common.adjust-your-search')}
                  style={{
                    height: '100%',
                  }}
                />
              )}
            </>
          )}
        </DialogContent>
      </Box>
    </Dialog>
  )
}
