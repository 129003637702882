import React, { useState } from 'react'
import { tss } from 'tss-react/mui'

import { MoreHoriz } from '@mui/icons-material'
import { Box, Popover } from '@mui/material'

import { ThreeDotOption } from './ThreeDotOption'

const useStyles = tss.withName('ThreeDotMenu').create(({ theme }) => ({
  threeDot: {
    color: theme.palette['neutral-700'],
    width: '16px',
    height: '16px',
    marginTop: '12px',
  },
  popover: {
    width: '252px',
    padding: '8px',
    display: 'grid',
  },
  morehoriz: {
    '&:hover': {
      cursor: 'pointer',
    },
  },
}))

export type ThreeDotMenuOption = {
  label: string
  action?: () => void
  enabled: boolean
  guidingId?: string
  showChevron?: boolean
  subOptions?: ThreeDotMenuOption[]
}

export interface ThreeDotMenuProps {
  options: ThreeDotMenuOption[]
  guidingId?: string
}

const ThreeDotMenu: React.FC<ThreeDotMenuProps> = ({
  options,
  guidingId = 'threedotmenu',
}) => {
  const { classes } = useStyles()

  const [open, setOpen] = useState(false)
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null)

  return (
    <div
      ref={(ref) => {
        if (!ref) return

        ref.onclick = (e) => {
          setAnchorEl(e.currentTarget as HTMLElement)
          setOpen(true)
          e.stopPropagation()
        }
      }}
    >
      <Popover
        open={open}
        anchorEl={anchorEl}
        onClose={() => setOpen(false)}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
      >
        <Box
          className={classes.popover}
          data-guiding-id={`${guidingId}-popover`}
        >
          {options
            .filter(({ enabled }) => Boolean(enabled))
            .map((option, index) => (
              <ThreeDotOption
                key={index}
                option={{
                  ...option,
                  guidingId: `${guidingId}-${option.guidingId || index}`,
                }}
              />
            ))}
        </Box>
      </Popover>

      <Box
        className={classes.morehoriz}
        textAlign={'center'}
        data-testid="three-dot-menu"
        data-guiding-id={`${guidingId}-container`}
      >
        <MoreHoriz className={classes.threeDot} />
      </Box>
    </div>
  )
}

export default ThreeDotMenu
