import { tss } from 'tss-react/mui'

import CloseIcon from '@mui/icons-material/Close'
import Chip from '@mui/material/Chip'

const useStyles = tss.withName('TagList').create({
  flex: {
    display: 'contents',
  },
  tag: {
    borderRadius: '4px',
    marginBottom: '8px',
    marginRight: '8px',
    fontWeight: 'medium',
    maxWidth: '300px',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap',
  },
  breakWord: {
    overflowWrap: 'break-word',
  },
  noWrap: {
    whiteSpace: 'nowrap',
  },
  tagWrap: {
    flexWrap: 'wrap',
  },
})

interface TagListProps<T> {
  items: T[]
  nameSelector: (value: T) => string
  wrapTags?: boolean
  wrapWords?: boolean
  onDelete?: (value: T) => void
  className?: string
  backgroundColor?: string
  textColor?: string
  guidingId?: string
}

export function TagList<T>({
  items,
  nameSelector,
  wrapTags = true,
  wrapWords = true,
  onDelete,
  className = '',
  backgroundColor,
  textColor,
  guidingId,
}: TagListProps<T>) {
  const { classes, cx } = useStyles()

  return (
    <div className={`${classes.flex} ${wrapTags ? classes.tagWrap : ''}`}>
      {items.map((item, index) => (
        <Chip
          key={`${nameSelector(item)}-${index}`}
          className={cx(
            classes.tag,
            wrapWords ? classes.breakWord : classes.noWrap
          )}
          label={nameSelector(item)}
          onDelete={onDelete ? () => onDelete(item) : undefined}
          style={{
            backgroundColor: backgroundColor || '#EFF6FF',
            color: textColor || '#1E40AF',
          }}
          deleteIcon={
            <CloseIcon
              style={{ backgroundColor: 'transparent', boxShadow: 'none' }}
              data-guiding-id={`${guidingId}-${index}-remove`}
            />
          }
          data-guiding-id={`${guidingId}-${index}`}
        />
      ))}
    </div>
  )
}

export default TagList
