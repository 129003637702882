import { useState } from 'react'
import { navigateToUrl } from 'single-spa'

import ErrorOutlineIcon from '@mui/icons-material/ErrorOutline'
import {
  Dialog,
  DialogActions,
  DialogTitle,
  DialogContent,
  DialogContentText,
  Button,
} from '@mui/material'

export const ErrorHandler = () => {
  const [modalOpen, setModalOpen] = useState(true)

  const goToMainPage = () => {
    setModalOpen(false)

    const currentPortal = sessionStorage.getItem('portal')
    navigateToUrl(`${process.env.URL_BASE_PATH}/${currentPortal}/dashboard`)
  }

  return (
    <Dialog open={modalOpen} onClose={() => setModalOpen(false)}>
      <DialogTitle textAlign={'center'} id="alert-dialog-title">
        Unexpected Error
      </DialogTitle>
      <DialogContent>
        <DialogContentText textAlign={'center'}>
          <ErrorOutlineIcon
            sx={{
              color: '#BD0606',
              fontSize: '4rem',
              marginBottom: '1rem',
            }}
          />
        </DialogContentText>
        <DialogContentText id="alert-dialog-description">
          An error occurred. Try reloading the page.
        </DialogContentText>
        <DialogContentText>
          If the problem persists, please contact support.
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button
          onClick={() => {
            setModalOpen(false)
            window.location.reload()
          }}
        >
          Reload
        </Button>
        <Button onClick={() => goToMainPage()}>Go to dashboard</Button>
      </DialogActions>
    </Dialog>
  )
}
