import { act, fireEvent, screen, waitFor } from '@testing-library/react'

import { TestFormFieldsData } from './types'
import {
  TestFormSubmissionField,
  TestFormSubmissionsData,
} from './types/testFormSubmissionsData'

export class FormTestingUtility {
  static async testFormFields(setup: () => TestFormFieldsData) {
    const { testCasesRecords, renderForm } = setup()

    describe.each<(typeof testCasesRecords)[number]>(testCasesRecords)(
      'renders fields correctly',
      ({ fields, testCases }) => {
        const title = fields.reduce(
          (title, { name }) =>
            `${title || ''}${title ? `\n${' '.repeat(6)}` : ''}${name}`,
          ''
        )

        testCases.forEach(
          ({ description, setupPrerequisites, expectFieldToDisplay }) => {
            describe(title, () => {
              it(description, async () => {
                const testExecutions = fields.map(
                  async ({ testId, placeholder }) =>
                    async () => {
                      await act(async () => {
                        renderForm()
                      })

                      await setupPrerequisites?.()

                      const expectField = expect(
                        screen.queryByTestId(testId as string)
                      ) as any

                      if (!expectFieldToDisplay) {
                        expectField.not.toBeInTheDocument()
                        return
                      }
                      expectField.toBeInTheDocument()

                      if (placeholder) {
                        expectField.toHaveTextContent(placeholder as string)
                      }
                    }
                )

                await Promise.all(testExecutions)
              })
            })
          }
        )
      }
    )
  }

  static async testFormSubmissions(setup: () => TestFormSubmissionsData) {
    const { testCases, submitButtonTestId, renderForm } = setup()

    const clickSubmitButton = () =>
      fireEvent.click(screen.getByTestId(submitButtonTestId))

    const handleFillFields = async (fields: TestFormSubmissionField[]) => {
      const fillFieldsExecutions = fields.map(
        ({ data: { testId, name, error }, fill, dependentFields }) =>
          new Promise<void>(async (resolve) => {
            try {
              expect(screen.queryByTestId(testId as string)).not.toBeNull()
            } catch {
              throw new Error(`Test Failed to find field "${name}"`)
            }

            if (error) {
              clickSubmitButton()

              await waitFor(() => {
                expect(screen.queryByText(error as string)).not.toBeNull()
              })
            }

            await act(async () => {
              await waitFor(async () => {
                await fill()

                if (error) {
                  expect(screen.queryByText(error as string)).toBeNull()
                }
              })
            })

            if (dependentFields) {
              try {
                await handleFillFields(dependentFields)
              } catch (error) {
                throw new Error(
                  `Error while filling dependent fields of "${name}": ${error.message}`
                )
              }
            }

            resolve()
          })
      )

      await Promise.all(fillFieldsExecutions)
    }

    describe.each<(typeof testCases)[number]>(testCases)(
      'submits form correctly',
      ({ description, fields, setupPrerequisites, expectAfterSubmission }) => {
        it(description, async () => {
          act(() => {
            renderForm()
          })

          await setupPrerequisites?.()
          await handleFillFields(fields)

          clickSubmitButton()
          await expectAfterSubmission()
        })
      }
    )
  }
}
