import React from 'react'
import { useTranslation } from 'react-i18next'
import { tss } from 'tss-react/mui'

import { Box, Typography } from '@mui/material'

import { Transaction } from '@shared/api/src'
import { TransactionStatus } from '@shared/components'
import { formatDatetimeWithTimezone, currency } from '@shared/utils'

export interface RetryEntryNoExpansion {
  id: string
  rejected_transaction_id: string
  retry_transaction_id?: string | null
  return_fee_transaction_id?: string | null
  return_fee: number
  created_ts: number
  created_user_id: string
  retry_transaction: any
  return_fee_transaction?: any | null
}
interface RetryEntryHistorySectionProps {
  transaction: Pick<Transaction, 'id' | 'rejected_transaction_ach_retries'>
  tz: string
  retryEntries: RetryEntryNoExpansion[]
  onViewTransactionDetail: (transactionId: string) => void
  onViewOriginalTransaction?: (transactionId: string) => void
}
const useStyles = tss
  .withName('RetryEntryHistorySection')
  .create(({ theme }) => ({
    container: {
      marginTop: theme.spacing(2),
    },
    titleRow: {
      display: 'flex',
      justifyContent: 'space-between',
      alignItems: 'center',
      marginBottom: theme.spacing(2),
    },
    viewOriginalLink: {
      color: theme.palette.primary.main,
      fontFamily: 'Inter',
      fontSize: '14px',
      fontWeight: 500,
      lineHeight: '20px',
      cursor: 'pointer',
    },
    headersRow: {
      width: '100%',
      display: 'grid',
      gridTemplateColumns: '130px 1fr 1fr',
      columnGap: theme.spacing(2),
      marginBottom: theme.spacing(1),
    },
    headerText: {
      color: theme.palette.text.secondary,
      fontFamily: 'Inter',
      fontSize: '14px',
      fontWeight: 400,
      lineHeight: '20px',
      textAlign: 'left',
    },
    row: {
      width: '100%',
      display: 'grid',
      gridTemplateColumns: '130px 1fr 1fr',
      columnGap: theme.spacing(2),
      marginBottom: theme.spacing(1),
      alignItems: 'center',
    },
    dateWrapper: {
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'flex-start',
    },
    dateMainText: {
      color: theme.palette.text.primary,
      fontFamily: 'Inter',
      fontSize: '14px',
      fontWeight: 600,
      lineHeight: '20px',
    },
    dateSubText: {
      color: theme.palette.text.secondary,
      fontFamily: 'Inter',
      fontSize: '12px',
      fontWeight: 500,
      lineHeight: '16px',
    },
    statusCell: {
      display: 'flex',
      justifyContent: 'flex-start',
      alignItems: 'center',
    },
    statusInner: {
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
    },
    detailCell: {
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'flex-start',
      whiteSpace: 'nowrap',
    },
    detailLink: {
      color: theme.palette.primary.main,
      fontFamily: 'Inter',
      fontSize: '14px',
      fontWeight: 500,
      lineHeight: '20px',
      cursor: 'pointer',
    },
    detailNoLink: {
      color: theme.palette.text.primary,
      fontFamily: 'Inter',
      fontSize: '14px',
      fontWeight: 500,
      lineHeight: '20px',
    },
    returnFeeLabel: {
      color: theme.palette.primary.main,
      fontFamily: 'Inter',
      fontSize: '14px',
      fontWeight: 500,
      lineHeight: '20px',
    },
    returnFeeValue: {
      color: theme.palette.text.secondary,
      fontFamily: 'Inter',
      fontSize: '14px',
      fontWeight: 500,
      lineHeight: '20px',
      marginTop: theme.spacing(0.25),
    },
  }))

const RetryEntryHistorySection: React.FC<RetryEntryHistorySectionProps> = ({
  transaction,
  tz,
  retryEntries,
  onViewTransactionDetail,
  onViewOriginalTransaction,
}) => {
  const { t } = useTranslation()
  const { classes } = useStyles()
  const isOriginalTransaction =
    transaction?.rejected_transaction_ach_retries?.length >= 1

  const formattedDate = (retryDateTime) => {
    const [
      retryEntryDate,
      retryEntryTime,
      retryEntryMeridiem,
      retryEntryTimezone,
    ] = retryDateTime?.split(' ')
    if (!retryDateTime) return
    return (
      <Box className={classes.dateWrapper}>
        <Typography
          className={classes.dateMainText}
          data-testid="retry-entry-date-main"
        >
          {retryEntryDate}
        </Typography>
        <Typography
          className={classes.dateSubText}
          data-testid="retry-entry-date-sub"
        >
          {retryEntryTime} {retryEntryMeridiem} {retryEntryTimezone}
        </Typography>
      </Box>
    )
  }

  return (
    <Box className={classes.container}>
      <Box className={classes.titleRow}>
        <Typography
          variant="h6"
          gutterBottom
          data-testid="retry-entry-history-title"
        >
          {t('common.retry-entry-history')}
        </Typography>

        {!isOriginalTransaction && (
          <Typography
            className={classes.viewOriginalLink}
            data-testid="retry-entry-view-original-transaction"
            onClick={() => {
              if (onViewOriginalTransaction) {
                const transactionId = retryEntries[0].rejected_transaction_id
                if (transactionId) {
                  onViewOriginalTransaction(transactionId)
                }
              }
            }}
          >
            {t('common.retry-entry-history.view-original-transaction')}
          </Typography>
        )}
      </Box>

      {retryEntries.length === 0 ? (
        <Typography variant="body2" data-testid="retry-entry-no-records">
          {t('common.no-records-found')}
        </Typography>
      ) : (
        <>
          <Box className={classes.headersRow}>
            <Typography
              className={classes.headerText}
              data-testid="retry-entry-header-date"
            >
              {t('common.date')}
            </Typography>
            <Typography
              className={classes.headerText}
              data-testid="retry-entry-header-status"
            >
              {t('common.status')}
            </Typography>
            <Typography
              className={classes.headerText}
              data-testid="retry-entry-header-details"
            >
              {t('common.details')}
            </Typography>
          </Box>

          {retryEntries.map((entry, index) => {
            const retryDateTime = formatDatetimeWithTimezone(
              entry.retry_transaction.created_ts,
              tz
            )

            const attemptVerbiage = t(
              index === 0
                ? 'common.retry-entry-history.first-attempt'
                : 'common.retry-entry-history.second-attempt'
            )

            return (
              <React.Fragment key={entry.id}>
                <Box className={classes.row} data-testid="retry-entry-row">
                  {formattedDate(retryDateTime)}
                  <Box className={classes.statusCell}>
                    <Box className={classes.statusInner}>
                      <TransactionStatus
                        statusCode={entry.retry_transaction.status_code}
                      />
                    </Box>
                  </Box>

                  <Box className={classes.detailCell}>
                    {entry.retry_transaction_id === transaction.id ? (
                      <Typography
                        className={classes.detailNoLink}
                        data-testid="retry-transaction-non-clickable"
                      >
                        {attemptVerbiage}
                      </Typography>
                    ) : (
                      <Typography
                        className={classes.detailLink}
                        data-testid="retry-transaction-link"
                        onClick={() =>
                          entry.retry_transaction_id &&
                          onViewTransactionDetail(entry.retry_transaction_id)
                        }
                      >
                        {attemptVerbiage}
                      </Typography>
                    )}
                  </Box>
                </Box>

                {entry.return_fee_transaction && (
                  <Box className={classes.row} data-testid="return-fee-row">
                    {formattedDate(retryDateTime)}

                    <Box className={classes.statusCell}>
                      <Box className={classes.statusInner}>
                        <TransactionStatus
                          statusCode={entry.return_fee_transaction.status_code}
                        />
                      </Box>
                    </Box>

                    <Box className={classes.detailCell}>
                      {entry.return_fee_transaction_id === transaction.id ? (
                        <>
                          <Typography
                            className={classes.detailNoLink}
                            data-testid="return-fee-label"
                          >
                            {t('common.retry-entry-history.return-fee')}
                          </Typography>
                          <Typography
                            className={classes.returnFeeValue}
                            data-testid="return-fee-value"
                          >
                            {currency(entry.return_fee, true)}
                          </Typography>
                        </>
                      ) : (
                        <Box
                          sx={{ cursor: 'pointer' }}
                          data-testid="return-fee-transaction-link"
                          onClick={() =>
                            entry.return_fee_transaction_id &&
                            onViewTransactionDetail(
                              entry.return_fee_transaction_id
                            )
                          }
                        >
                          <Typography
                            className={classes.returnFeeLabel}
                            data-testid="return-fee-label"
                          >
                            {t('common.retry-entry-history.return-fee')}
                          </Typography>
                          <Typography
                            className={classes.returnFeeValue}
                            data-testid="return-fee-value"
                          >
                            {currency(entry.return_fee, true)}
                          </Typography>
                        </Box>
                      )}
                    </Box>
                  </Box>
                )}
              </React.Fragment>
            )
          })}
        </>
      )}
    </Box>
  )
}

export default RetryEntryHistorySection
