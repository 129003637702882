// prettier-ignore
export const reasonCodes: { [key: string]: string } = {
  '0': 'N/A',
  '1000': 'CC - Approved / ACH - Accepted',
  'cc1000': 'CC - Approved',
  'ach1000': 'ACH - Accepted',
  '1001': 'AuthCompleted',
  '1002': 'Forced',
  '1003': 'AuthOnly Declined',
  '1004': 'Validation Failure (System Run Transaction)',
  '1005': 'Processor Response Invalid',
  '1006': 'Approved - PIN Debit',
  '1200': 'Voided',
  '1201': 'Partial Approval',
  '1240': 'Approved - Optional Fields Missing',
  '1301': 'Fraud Detection - Location Shutdown',
  '1422': 'Validation Error',
  '1500': 'Generic Decline',
  '1510': 'Call',
  '1518': 'Transaction Not Permitted (Terminal)',
  '1520': 'Pickup Card',
  '1530': 'Retry Transaction',
  '1531': 'Communication Error',
  '1540': 'Setup Issue, contact Support',
  '1541': 'Device is not signature capable',
  '1588': 'Data could not be de-tokenized',
  '1599': 'Other Reason',
  '1601': 'Generic Decline',
  '1602': 'Call',
  '1603': 'No Reply',
  '1604': 'Pickup Card - No Fraud',
  '1605': 'Pickup Card - Fraud',
  '1606': 'Pickup Card - Lost',
  '1607': 'Pickup Card - Stolen',
  '1608': 'Account Error',
  '1609': 'Already Reversed',
  '1610': 'Bad PIN',
  '1611': 'Cashback Exceeded',
  '1612': 'Cashback Not Available',
  '1613': 'CID Error',
  '1614': 'Date Error',
  '1615': 'Do Not Honor',
  '1616': 'NSF',
  '1617': 'Exceeded Withdrawal Limit',
  '1618': 'Invalid Service Code',
  '1619': 'Exceeded activity limit',
  '1620': 'Violation',
  '1621': 'Encryption Error',
  '1622': 'Card Expired',
  '1623': 'Renter',
  '1624': 'Security Violation',
  '1625': 'Card Not Permitted',
  '1626': 'Trans Not Permitted',
  '1627': 'System Error',
  '1628': 'Bad Merchant ID',
  '1629': 'Duplicate Batch (Already Closed)',
  '1630': 'Batch Rejected',
  '1631': 'Account Closed',
  '1632': 'Payment Facilitator Invalid',
  '1640': 'Declined - Required Fields Missing',
  '1641': 'Previously Declined Transaction (Reason Code 1640)',
  '1650': 'Contact Support',
  '1651': 'Max Sending - Throttle Limit Hit',
  '1652': 'Max Attempts Exceeded',
  '1653': 'Contact Support',
  '1654': 'Voided - Online Reversal Failed',
  '1655': 'Decline (AVS Auto Reversal)',
  '1656': 'Decline (CVV Auto Reversal)',
  '1657': 'Decline (Partial Auth Auto Reversal)',
  '1658': 'Expired Authorization',
  '1659': 'Declined - Partial Approval not Supported',
  '1660': 'Bank Account Error, please delete and re-add Account Vault',
  '1661': 'Declined AuthIncrement',
  '1662': 'Auto Reversal - Processor cant settle',
  '1663': 'Manager Override Needed',
  '1664': 'Account Vault Inaccessible',
  '1665': 'Contact Inaccessible',
  '1666': 'Amount Error',
  '1667': 'Action Not Allowed In Current State',
  '1668': 'Original Authorization Not Valid',
  '1800': 'Incorrect CVV',
  '1801': 'Duplicate Transaction',
  '1802': 'MID/TID Not Registered',
  '1803': 'Stop Recurring',
  '1804': 'No Transactions in Batch',
  '1805': 'Batch Does Not Exist',
  '2101': 'Rejected-R01',
  '2102': 'Rejected-R02',
  '2103': 'Rejected-R03',
  '2104': 'Rejected-R04',
  '2105': 'Rejected-R05',
  '2106': 'Rejected-R06',
  '2107': 'Rejected-R07',
  '2108': 'Rejected-R08',
  '2109': 'Rejected-R09',
  '2110': 'Rejected-R10',
  '2111': 'Rejected-R11',
  '2112': 'Rejected-R12',
  '2113': 'Rejected-R13',
  '2114': 'Rejected-R14',
  '2115': 'Rejected-R15',
  '2116': 'Rejected-R16',
  '2117': 'Rejected-R17',
  '2118': 'Rejected-R18',
  '2119': 'Rejected-R19',
  '2120': 'Rejected-R20',
  '2121': 'Rejected-R21',
  '2122': 'Rejected-R22',
  '2123': 'Rejected-R23',
  '2124': 'Rejected-R24',
  '2125': 'Rejected-R25',
  '2126': 'Rejected-R26',
  '2127': 'Rejected-R27',
  '2128': 'Rejected-R28',
  '2129': 'Rejected-R29',
  '2130': 'Rejected-R30',
  '2131': 'Rejected-R31',
  '2132': 'Rejected-R32',
  '2133': 'Rejected-R33',
  '2134': 'Rejected-R34',
  '2135': 'Rejected-R35',
  '2136': 'Rejected-R36',
  '2137': 'Rejected-R37',
  '2138': 'Rejected-R38',
  '2139': 'Rejected-R39',
  '2140': 'Rejected-R40',
  '2141': 'Rejected-R41',
  '2142': 'Rejected-R42',
  '2143': 'Rejected-R43',
  '2144': 'Rejected-R44',
  '2145': 'Rejected-R45',
  '2146': 'Rejected-R46',
  '2147': 'Rejected-R47',
  '2150': 'Rejected-R50',
  '2151': 'Rejected-R51',
  '2152': 'Rejected-R52',
  '2153': 'Rejected-R53',
  '2161': 'Rejected-R61',
  '2162': 'Rejected-R62',
  '2163': 'Rejected-R63',
  '2164': 'Rejected-R64',
  '2165': 'Rejected-R65',
  '2166': 'Rejected-R66',
  '2167': 'Rejected-R67',
  '2168': 'Rejected-R68',
  '2169': 'Rejected-R69',
  '2170': 'Rejected-R70',
  '2171': 'Rejected-R71',
  '2172': 'Rejected-R72',
  '2173': 'Rejected-R73',
  '2174': 'Rejected-R74',
  '2180': 'Rejected-R80',
  '2181': 'Rejected-R81',
  '2182': 'Rejected-R82',
  '2183': 'Rejected-R83',
  '2200': 'Processor Void',
  '2201': 'Rejected-C01',
  '2202': 'Rejected-C02',
  '2203': 'Rejected-C03',
  '2204': 'Rejected-C04',
  '2205': 'Rejected-C05',
  '2206': 'Rejected-C06',
  '2207': 'Rejected-C07',
  '2208': 'Rejected-C08',
  '2209': 'Rejected-C09',
  '2210': 'Rejected-C10',
  '2211': 'Rejected-C11',
  '2212': 'Rejected-C12',
  '2213': 'Rejected-C13',
  '2261': 'Rejected-C61',
  '2262': 'Rejected-C62',
  '2263': 'Rejected-C63',
  '2264': 'Rejected-C64',
  '2265': 'Rejected-C65',
  '2266': 'Rejected-C66',
  '2267': 'Rejected-C67',
  '2268': 'Rejected-C68',
  '2269': 'Rejected-C69',
  '2301': 'Rejected-X01',
  '2304': 'Rejected-X04',
  '2305': 'Rejected-X05',
  '2306': 'Rejected-X06',
  '2307': 'Rejected-X07',
  '2308': 'Rejected-X08',
  '2309': 'Rejected-X09',
  '2310': 'Rejected-X10',
  '2321': 'Rejected-X21',
  '2322': 'Rejected-X22',
  '2323': 'Rejected-X23',
  '2329': 'Rejected-X29',
  '2345': 'Rejected-X45',
  '2371': 'Rejected-X71',
  '2372': 'Rejected-X72',
  '2373': 'Rejected-X73',
}
