import { Statement } from '../../../../types'
import { ApiService, App } from '../../commons'
import { mapResult } from '../../hooks/mapResult'

type StatementDownload = {
  url: string
}

export class StatementsService extends ApiService<Statement> {
  async list(
    merchantId: string
  ): Promise<{ [key: string]: unknown; type: string; list: Statement[] }> {
    return this.apiRequest({
      url: `${merchantId}/statements`,
      method: 'GET',
    })
  }

  async get(merchantId: string): Promise<Statement> {
    return this.apiRequest<Statement>({
      url: `${merchantId}/documents`,
      method: 'GET',
    })
  }

  async download(
    merchantId: string,
    statementID: string
  ): Promise<StatementDownload> {
    return this.apiRequest({
      url: `${merchantId}/statements/${statementID}/download`,
      method: 'GET',
    })
  }

  find(params?: any): Promise<any> {
    const merchantId = params?.query?.merchantId

    const dynamicService = this.app
      .get('connection')
      .service(`merchants/${merchantId}/statements`)

    const newParams = {
      ...params,
      query: {
        ...params.query,
      },
    }
    delete newParams.query.merchantId

    return dynamicService.find(newParams)
  }
}

declare module '../../commons' {
  interface ServiceTypes {
    statements: StatementsService
  }
}

export const statements = (app: App) => {
  const connection = app.get('connection')

  app.use(
    'statements',
    new StatementsService(connection.service('merchants'), app)
  )

  app.service('statements').hooks({
    around: {
      all: [mapResult()],
    },
  })
}
