import { useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router-dom'
import { tss } from 'tss-react/mui'

import { ArrowForward, ErrorOutline } from '@mui/icons-material'
import { Box, Typography } from '@mui/material'

import appTheme from '@shared/design'
import { useFtpPortalHubCommunication } from '@shared/hooks'

import Button from '../../buttons/Button'

const useStyles = tss.withName('UserNotAllowed').create(({ theme }) => ({
  container: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    height: '50vh',
    backgroundColor: 'white',
  },
  icon: {
    display: 'flex',
    padding: '16px',
    alignItems: 'flex-start',
    gap: '10px',
    borderRadius: '100px',
    height: '64px',
    width: '64px',
    backgroundColor: 'var(--red-100, #FEE2E2)',
    marginBottom: '24px',
  },
}))

const PageNotFound = () => {
  const { t } = useTranslation()
  const { classes } = useStyles()
  const navigate = useNavigate()

  const { setAppBarTitle } = useFtpPortalHubCommunication()

  const handleClick = () => {
    const currentPortal = sessionStorage.getItem('portal')

    navigate(`/${currentPortal}/dashboard`)
  }

  useEffect(() => {
    setAppBarTitle(t('common.page-not-found'))
  }, [])

  return (
    <Box className={classes.container}>
      <Box className={classes.icon}>
        <ErrorOutline
          style={{
            color: appTheme.palette.negative,
            height: '32px',
            width: '32px',
          }}
        />
      </Box>
      <Typography variant="h4" component="h1" marginBottom="16px">
        {t('common.page-not-found')}
      </Typography>
      <Typography variant="body1" marginBottom="32px">
        {t('common.page-not-found-description')}
      </Typography>

      <Button
        label={t('common.go-to-dashboard')}
        icon={<ArrowForward />}
        onClick={handleClick}
      />
    </Box>
  )
}

export default PageNotFound
