import React from 'react'
import { tss } from 'tss-react/mui'

import { Grid } from '@mui/material'

export interface PageLayoutContainerMainBlocksProps {
  testID?: string
  isFullWidth?: boolean
}

const useStyles = tss
  .withName('PageLayoutContainerMainBlocks')
  .withParams<{ isFullWidth: boolean }>()
  .create(({ theme, isFullWidth }) => ({
    root: {
      paddingTop: '0 !important',
      paddingBottom: '0 !important',
      paddingLeft: '0 !important',
      [theme.breakpoints.down('lg')]: {
        paddingRight: '0 !important',
      },
      [theme.breakpoints.up('lg')]: {
        paddingRight: isFullWidth ? '0 !important' : '24px !important',
      },
    },
  }))

export const PageLayoutContainerMainBlocks: React.FC<
  PageLayoutContainerMainBlocksProps
> = ({
  children,
  testID = 'page-layout-container-main',
  isFullWidth = false,
}) => {
  const { classes } = useStyles({ isFullWidth })
  return (
    <Grid
      item
      container
      sm={12}
      lg={isFullWidth ? 12 : 8}
      xl={isFullWidth ? 12 : 9}
      data-testid={testID}
      gap="24px"
      className={classes.root}
    >
      {children}
    </Grid>
  )
}
