import React from 'react'
import { tss } from 'tss-react/mui'

import { CheckCircle } from '@mui/icons-material'
import { Box, Typography } from '@mui/material'

import { Button } from '@shared/components'
import appTheme from '@shared/design'

interface ActionButton {
  label: string
  onClick: () => void
  style?: string
  show: boolean
  guidingId?: string
}

interface ConfirmationProps {
  title: string
  verbiage?: string
  details: React.ReactNode
  buttons: ActionButton[]
  fullHeight?: boolean
  icon?: React.ReactElement
}

const useStyles = tss
  .withName('Confirmation')
  .withParams<{ fullHeight: boolean }>()
  .create(({ theme, fullHeight }) => ({
    container: {
      padding: '96px 0',
      backgroundColor: 'white',
      height: fullHeight ? 'calc(100vh - 126px)' : 'auto',
    },
    dataContainer: {
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      gap: '32px',
      width: '461px',
      margin: 'auto',
    },
    title: {
      color: theme.palette['neutral-900'],
      fontSize: '24px',
      fontWeight: 500,
      lineHeight: '32px',
      textAlign: 'center',
    },
    icon: {
      height: '64px',
      width: '64px',
    },
    buttonsContainer: {
      display: 'flex',
      gap: '24px',
      justifyContent: 'center',
      marginTop: '40px',
    },
    detailsContainer: {
      color: theme.palette['neutral-600'],
      textAlign: 'center',
      fontSize: '14px',
      fontWeight: 400,
      lineHeight: '20px',
    },
  }))

const Confirmation = ({
  title,
  verbiage,
  details,
  buttons,
  fullHeight = true,
  icon = <CheckCircle style={{ color: appTheme.palette.positive }} />,
}: ConfirmationProps) => {
  const { classes } = useStyles({
    fullHeight,
  })
  return (
    <Box className={classes.container}>
      <Box className={classes.dataContainer}>
        {React.cloneElement(icon, { className: classes.icon })}
        <Box>
          <Typography className={classes.title}>{title}</Typography>
          {verbiage && (
            <Typography textAlign="center" marginTop="6px">
              {verbiage}
            </Typography>
          )}
        </Box>

        <Box className={classes.detailsContainer}>{details}</Box>
      </Box>
      <Box className={classes.buttonsContainer}>
        {buttons
          .filter((button) => button.show)
          .map((button, index) => (
            <Button
              key={index}
              label={button.label}
              onClick={button.onClick}
              containerClassName={button.style}
              color="secondary"
              guidingId={button.guidingId}
            />
          ))}
      </Box>
    </Box>
  )
}

export default Confirmation
