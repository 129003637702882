import { ApiService, App } from '../../commons'
import { mapResult } from '../../hooks/mapResult'
import { Platform } from '../../schemas/types'

export class PlatformsService extends ApiService<Platform> {}

declare module '../../commons' {
  interface ServiceTypes {
    platforms: PlatformsService
  }
}

export const partnerPortalPlatforms = (app: App) => {
  const connection = app.get('connection')

  app.use(
    'platforms',
    new PlatformsService(connection.service('partner/platforms'), app)
  )

  app.service('platforms').hooks({
    around: {
      all: [mapResult()],
    },
  })
}
