import { ApiService, ApiServiceParams, App } from '../../commons'
import { mapResult } from '../../hooks/mapResult'
import {
  ApplicationTemplate,
  AvailableEquipment,
  Equipment,
  EquipmentRequest,
  PaymentAndVelocity,
  PricingPlans,
  PartnerTemplateBasePricePlans,
  PartnerTemplatePricingPlan,
  PricingPlanRequest,
} from '../../schemas/types'
import { buildQuery } from '../../utils/buildQuery'

export class TemplatesService extends ApiService<ApplicationTemplate> {
  async patchPaymentVelocity({
    templateId,
    body,
  }: {
    templateId: string
    body: Partial<PaymentAndVelocity>
  }): Promise<PaymentAndVelocity> {
    return this.apiRequest({
      url: `${templateId}/payment-velocity`,
      method: 'PATCH',
      body: body,
      headers: {
        'Content-Type': 'application/json',
      },
    })
  }

  async getPricingPlanDetails({
    templateId,
    planId,
  }: {
    templateId: string
    planId: string
  }): Promise<PartnerTemplateBasePricePlans> {
    return this.apiRequest({
      url: `${templateId}/base-price-plans/${planId}`,
      method: 'GET',
    })
  }

  async getPaymentVelocity(templateId: string): Promise<PaymentAndVelocity> {
    return this.apiRequest({
      url: `${templateId}/payment-velocity`,
      method: 'GET',
    })
  }

  async getAvailableEquipment(
    templateId: string
  ): Promise<AvailableEquipment[]> {
    return this.apiRequest({
      url: `${templateId}/available-equipment`,
      method: 'GET',
    })
  }

  async postEquipment(
    templateId: string,
    payload: Partial<EquipmentRequest>
  ): Promise<Equipment> {
    return this.apiRequest({
      url: `${templateId}/equipment`,
      method: 'POST',
      body: payload,
      headers: {
        'Content-Type': 'application/json',
      },
    })
  }

  // This function is intended to only be used to update the agent_steps fields on Products page.
  async updateProductAgentStep(
    templateId: string,
    payload: {
      agent_steps: number
    }
  ): Promise<void> {
    return this.apiRequest({
      url: `${templateId}/products`,
      body: payload,
      method: 'PATCH',
      headers: {
        'Content-Type': 'application/json',
      },
    })
  }

  async updateEquipment(
    {
      templateId,
      equipmentId,
    }: {
      templateId: string
      equipmentId: number
    },
    payload: EquipmentRequest
  ): Promise<Equipment> {
    return this.apiRequest({
      url: `${templateId}/equipment/${equipmentId}`,
      method: 'PATCH',
      body: payload,
      headers: {
        'Content-Type': 'application/json',
      },
    })
  }

  async getEquipment(
    templateId: string,
    params?: ApiServiceParams
  ): Promise<Equipment[]> {
    const query = buildQuery(params)

    return this.apiRequest({
      url: `${templateId}/equipment${
        query.length ? '?' + query.join('&') : ''
      }`,
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
      },
    })
  }

  async deleteEquipment(templateId: string, productId: string) {
    return this.apiRequest({
      url: `${templateId}/equipment/${productId}`,
      method: 'DELETE',
      headers: {
        'Content-Type': 'application/json',
      },
    })
  }

  async getPricingPlans(templateId: string): Promise<PricingPlans[]> {
    return this.apiRequest({
      url: `${templateId}/base-price-plans`,
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
      },
    })
  }

  async putPricingPlan(templateId: string, payload: PricingPlanRequest) {
    return this.apiRequest({
      url: `${templateId}/price-plan`,
      method: 'PUT',
      body: payload,
    })
  }

  async getPricingPlan(
    templateId: string
  ): Promise<PartnerTemplatePricingPlan> {
    return this.apiRequest({
      url: `${templateId}/price-plan`,
      method: 'GET',
    })
  }

  async activate(templateId: string): Promise<void> {
    return this.apiRequest({
      url: `${templateId}/activate`,
      method: 'PATCH',
      body: {
        status_code: 1,
      },
    })
  }
}

declare module '../../commons' {
  interface ServiceTypes {
    templates: TemplatesService
  }
}

export const partnerPortalTemplates = (app: App) => {
  const connection = app.get('connection')

  app.use(
    'templates',
    new TemplatesService(connection.service('partner/templates'), app),
    {
      methods: [
        'getEquipment',
        'getAvailableEquipment',
        'patchPaymentVelocity',
        'getPricingPlans',
        'getPricingPlanDetails',
        'getPricingPlan',
      ],
    }
  )

  app.service('templates').hooks({
    around: {
      all: [mapResult()],
    },
  })
}
