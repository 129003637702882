import { FC, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { tss } from 'tss-react/mui'

import { Box, Typography } from '@mui/material'

import { User } from '@shared/api'
import {
  FieldGroupContainer,
  Button,
  CopyToClipboard,
} from '@shared/components'
import { UserStatusEnum } from '@shared/types'
import { formatKey } from '@shared/utils'

import {
  GenerateKeyModal,
  KeyType,
} from '../modals/generate-key-modal/GenerateKeyModal'

const useStyles = tss.withName('AdvancedSettings').create(({ theme }) => ({
  subtitle: {
    color: theme.palette['neutral-900'],
    paddingBottom: '.5em',
  },
  description: {
    padding: '.5em 0 1em 0',
    color: theme.palette['neutral-600'],
    fontSize: '14px',
  },
  keyContainer: {
    display: 'flex',
    padding: '1em 0',
    alignItems: 'center',
    justifyContent: 'space-between',
  },
  keyDescription: {
    maxWidth: '80%',
  },
}))

interface AdvancedSettingsProps {
  user: User
  setUser: React.Dispatch<React.SetStateAction<User>>
  isNewUserProcess?: boolean
  guidingId?: string
}

const AdvancedSettings: FC<AdvancedSettingsProps> = ({
  user,
  setUser,
  isNewUserProcess = false,
  guidingId,
}) => {
  const { t } = useTranslation()
  const { classes, cx } = useStyles()

  const [keyToGenerate, setKeyToGenerate] = useState<KeyType | null>(null)

  const [showFullApiKey, setShowFullApiKey] = useState(false)
  const [showFullHashKey, setShowFullHashKey] = useState(false)

  const getKeyDisplayText = (keyType: KeyType) => {
    if (keyType === KeyType.API_KEY && user.user_api_key) {
      return showFullApiKey ? user.user_api_key : formatKey(user.user_api_key)
    }

    if (keyType === KeyType.HASH_KEY && user.user_hash_key) {
      return showFullHashKey
        ? user.user_hash_key
        : formatKey(user.user_hash_key)
    }

    return EMPTY_FIELD_PLACEHOLDER
  }

  const EMPTY_FIELD_PLACEHOLDER = '-'

  return (
    <>
      <GenerateKeyModal
        open={!!keyToGenerate}
        keyType={keyToGenerate}
        user={user}
        setUser={setUser}
        onClose={(showKeysRecord) => {
          if (!!showKeysRecord?.showFullApiKey) setShowFullApiKey(true)
          if (!!showKeysRecord?.showFullHashKey) setShowFullHashKey(true)
          setKeyToGenerate(null)
        }}
      />

      <FieldGroupContainer
        title={
          isNewUserProcess
            ? t('common.login-access')
            : t('common.advanced-settings')
        }
        showToggle={!isNewUserProcess}
        showContent={isNewUserProcess}
        toggleTestID="advanced-settings-toggle"
        titleTestID="advanced-settings-title"
        guidingId={guidingId}
      >
        {!isNewUserProcess && (
          <Typography className={classes.description} variant="body1">
            {t('common.when-clicking-to-request-a-new-key')}
          </Typography>
        )}

        <Box className={classes.keyContainer}>
          <Box>
            <Typography
              className={classes.subtitle}
              variant="subtitle1"
              data-testid="api-key-field-title"
            >
              {t('common.api-key')}
            </Typography>

            <Typography
              className={cx(classes.description, classes.keyDescription)}
              variant="body1"
            >
              {t('common.an-api-key-can-be-used-for-unattended-access')}
            </Typography>

            <CopyToClipboard
              text={getKeyDisplayText(KeyType.API_KEY)}
              isClipboardIconShown={showFullApiKey}
              textTestid="api-key-text"
              guidingId="copy-api-key"
            />
          </Box>

          {user.status_code !== UserStatusEnum.Inactive && (
            <Button
              label={t('common.generate-api-key')}
              color="secondary"
              onClick={() => setKeyToGenerate(KeyType.API_KEY)}
              guidingId={`${guidingId}-generate-api-key`}
            />
          )}
        </Box>

        <Box className={classes.keyContainer}>
          <Box>
            <Typography
              className={classes.subtitle}
              variant="subtitle1"
              data-testid="hash-key-field-title"
            >
              {t('common.hash-key')}
            </Typography>

            <Typography
              className={cx(classes.description, classes.keyDescription)}
              variant="body1"
            >
              {t(
                'common.when-cliking-to-request-a-new-key-will-only-be-provided-one-time'
              )}
            </Typography>

            <CopyToClipboard
              text={getKeyDisplayText(KeyType.HASH_KEY)}
              isClipboardIconShown={showFullHashKey}
              textTestid="hash-key-text"
              guidingId="copy-hash-key"
            />
          </Box>

          {user.status_code === UserStatusEnum.Active && (
            <Button
              label={t('common.generate-hash-key')}
              color="secondary"
              onClick={() => setKeyToGenerate(KeyType.HASH_KEY)}
              guidingId={`${guidingId}-generate-hash-key`}
            />
          )}
        </Box>
      </FieldGroupContainer>
    </>
  )
}

export default AdvancedSettings
