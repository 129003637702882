import React, { ChangeEvent, useEffect, useState } from 'react'
import { tss } from 'tss-react/mui'

import { Box, Grid, SxProps, Theme, Typography } from '@mui/material'

import { Button, ButtonProps, Toggle } from '@shared/components'

const useStyles = tss
  .withName('FieldGroupContainerBlock')
  .create(({ theme }) => ({
    container: {
      padding: '24px',
      backgroundColor: 'white',
      borderRadius: '6px',
    },
    titleArea: {
      display: 'flex',
      justifyContent: 'space-between',
      marginBottom: '12px',
    },
    titleAreaHover: {
      ':hover': {
        cursor: 'pointer',
      },
    },
    title: {
      color: theme.palette['neutral-700'],
      fontFamily: 'Inter',
      fontSize: '20px',
      fontWeight: '500',
      lineHeight: '28px',
      marginBottom: '0px',
    },
    titleSubContainer: {
      color: theme.palette['neutral-700'],
      fontFamily: 'Inter',
      fontSize: '16px',
      fontWeight: '500',
      lineHeight: '28px',
      marginBottom: '0px',
    },
    description: {
      color: theme.palette['neutral-500'],
      fontFamily: 'Inter',
      fontSize: '14px',
      fontWeight: '400',
      lineHeight: '20px',
      marginTop: '0px',
      marginRight: '25px',
    },
  }))

export interface FieldGroupContainerBlockProps {
  style?: SxProps<Theme>
  className?: string
  title?: string
  description?: string
  titleTestID?: string
  toggleTestID?: string
  showToggle?: boolean
  showContent?: boolean
  forceOpen?: boolean
  buttonData?: ButtonProps
  titleDecorator?: React.ReactNode
  isSubContainer?: boolean
  guidingId?: string
}

export const FieldGroupContainerBlock: React.FC<
  FieldGroupContainerBlockProps
> = ({
  children,
  style,
  className,
  title,
  description,
  titleTestID,
  toggleTestID = 'toggle-input',
  showToggle = false,
  showContent = true,
  forceOpen = false,
  buttonData,
  titleDecorator,
  isSubContainer = false,
  guidingId = 'fieldgroupcontainerblock',
}) => {
  const { classes, cx } = useStyles()

  const [open, setOpen] = useState<boolean>(false)

  useEffect(() => {
    if (forceOpen) setOpen(forceOpen)
    else setOpen(showContent)
  }, [forceOpen, showContent])

  const onToggleChange = (_: ChangeEvent<HTMLInputElement>, checked: boolean) =>
    setOpen(checked)

  const onToggleAreaClick = () => {
    if (!showToggle) return
    setOpen(!open)
  }

  const applyTitleAreaClass =
    title || showToggle || titleDecorator || description

  return (
    <Grid
      item
      xs={12}
      sx={style}
      className={cx(classes.container, className)}
      data-guiding-id={`${guidingId}-container`}
    >
      <Box
        onClick={onToggleAreaClick}
        className={`${applyTitleAreaClass ? classes.titleArea : ''} ${
          showToggle ? classes.titleAreaHover : ''
        }`}
      >
        {(title || showToggle) && (
          <Box display="flex" gap="12px">
            <Box>
              <Typography
                className={`${
                  isSubContainer ? classes.titleSubContainer : classes.title
                }`}
                data-testid={titleTestID}
              >
                {title}
              </Typography>
              {titleDecorator}
              {description && (
                <Typography className={classes.description}>
                  {description}
                </Typography>
              )}
            </Box>
          </Box>
        )}
        {buttonData && (
          <Button {...buttonData} guidingId={`${guidingId}-button`} />
        )}
        {showToggle && (
          <Toggle
            checked={open}
            onChange={onToggleChange}
            testId={toggleTestID}
            guidingId={`${guidingId}-toggle`}
          />
        )}
      </Box>
      {open && <Box>{children}</Box>}
    </Grid>
  )
}
