import React, { forwardRef } from 'react'
import MaskedInput from 'react-text-mask'
import { tss } from 'tss-react/mui'

import { Box, TextField, Typography } from '@mui/material'
import InputAdornment from '@mui/material/InputAdornment'

import { createNumberMask } from '@shared/utils'

const useStyles = tss.withName('Input').create(({ theme }) => ({
  label: {
    color: `${theme?.palette['neutral-700']} !important`,
    fontFamily: 'Inter !important',
    fontSize: '14px !important',
    fontWeight: '500 !important',
    lineHeight: '20px !important',
  },
  required: {
    color: `${theme?.palette['negative']} !important`,
    fontFamily: 'Inter !important',
    fontSize: '14px !important',
    fontWeight: '500 !important',
    lineHeight: '20px !important',
    marginLeft: '3px !important',
  },
  input: {
    borderRadius: '6px',
    '& input': {
      padding: '12px !important',
      fontFamily: 'Inter !important',
      fontSize: '14px !important',
      fontWeight: '500 !important',
      lineHeight: '20px !important',
    },
    '& label.Mui-focused': {
      color: theme.palette['neutral-700'],
    },
    '& .MuiInput-underline:after': {
      borderBottomColor: theme.palette['neutral-300'],
      borderWidth: '1px',
    },
  },
  helperText: {
    marginLeft: '0 !important',
    fontFamily: 'Inter !important',
    fontSize: '12px !important',
    fontWeight: '500 !important',
    lineHeight: '16px !important',
    color: `${theme?.palette['neutral-500']} !important`,
  },
  helperTextError: {
    color: `${theme?.palette['negative']} !important`,
  },
  disabledInput: {
    '& .MuiTypography-body1': {
      color: `${theme?.palette['disabled']} !important`,
    },
  },
}))

export interface InputProps {
  label?: string
  value?: number
  placeholder?: string
  onChange: React.ChangeEventHandler<HTMLTextAreaElement | HTMLInputElement>
  onBlur?: React.FocusEventHandler<HTMLTextAreaElement | HTMLInputElement>
  error?: boolean
  helperText?: string
  required?: boolean
  disabled?: boolean
  style?: React.CSSProperties
  testId?: string
  inputRef?: React.Ref<any>
  autoFocus?: boolean
  guidingId?: string
  decimalLimit?: number
}

const InputPercentage = forwardRef<unknown, InputProps>(
  (
    {
      label,
      value,
      placeholder,
      onChange,
      onBlur,
      error = false,
      helperText,
      required = false,
      disabled = false,
      style,
      testId,
      autoFocus,
      guidingId,
      decimalLimit = 2,
    },
    ref
  ) => {
    const { classes } = useStyles()

    return (
      <Box sx={style}>
        {label && (
          <Box sx={{ display: 'flex', marginBottom: '.6em' }}>
            <Typography className={classes.label}>{label}</Typography>
            {required && (
              <Typography
                className={classes.required}
                data-testid={`${testId}-required`}
              >
                *
              </Typography>
            )}
          </Box>
        )}
        <TextField
          inputRef={ref}
          value={value}
          placeholder={placeholder}
          onChange={onChange}
          onBlur={onBlur}
          type={'string'}
          error={error}
          helperText={helperText}
          disabled={disabled}
          className={classes.input}
          fullWidth
          variant="outlined"
          size="small"
          inputProps={{
            'data-testid': testId,
            'data-guiding-id': guidingId,
            decimalLimit: decimalLimit,
          }}
          InputProps={{
            endAdornment: (
              <InputAdornment
                position="end"
                className={disabled ? classes.disabledInput : ''}
              >
                %
              </InputAdornment>
            ),
            inputComponent: PercentageMaskInput,
          }}
          FormHelperTextProps={{
            id: `${testId}-helper`,
            className: `${classes.helperText} ${
              error ? classes.helperTextError : ''
            }`,
          }}
          autoFocus={autoFocus}
        ></TextField>
      </Box>
    )
  }
)

const PercentageMaskInput = React.forwardRef<
  HTMLInputElement,
  { decimalLimit: number }
>((props, ref) => {
  const { decimalLimit, ...other } = props

  return (
    <MaskedInput
      {...other}
      guide={false}
      mask={createNumberMask({
        prefix: '',
        allowDecimal: true,
        decimalLimit: decimalLimit,
        integerLimit: 3,
      })}
      placeholder="0"
    />
  )
})

export default InputPercentage
