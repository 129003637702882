import { FC, useCallback, useEffect, useRef, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { navigateToUrl } from 'single-spa'
import { tss } from 'tss-react/mui'

import { Dialog, DialogActions, DialogContent, Typography } from '@mui/material'

import { api } from '@shared/api'
import { resetAuthorizationTimerEvent } from '@shared/api/src/axiosClient'
import { useNotification } from '@shared/hooks'
import { LOCAL_STORAGE_SESSION_EXP_KEY } from '@shared/hooks/useEnforceLogin'
import { useSub } from '@shared/hooks/useSub'

import { Button } from '../../'

export const DEFAULT_SESSION_EXPIRATION_MINS = 15
// The Modal should be shown 30 seconds before the Session expires.
export const OPEN_MODAL_MS = 30000

const getRedirectToLoginMs = (sessionExpirationMins: number) =>
  sessionExpirationMins * 1000 * 60

const getOpenModalMs = (sessionExpirationMins: number) =>
  sessionExpirationMins * 1000 * 60 - OPEN_MODAL_MS

const useStyles = tss.withName('InactivityModal').create(({ theme }) => ({
  container: {
    height: 'auto !important',
    padding: 0,
  },
  paper: {
    position: 'absolute',
    top: '15%',
    left: '50%',
    transform: 'translate(-50%, 0)',
    textAlign: 'center',
    width: '450px !important',
    padding: '1em',
    background: theme.palette.common.white,
    borderRadius: '.5em',
  },
  descriptionContainer: {
    padding: 0,
  },
  subtitle: {
    fontSize: '16px',
    fontWeight: 'bold',
  },
  description: {
    fontSize: '14px',
    marginTop: '1em',
  },
  descriptionOk: {
    fontWeight: 'bold',
  },
  actionButton: {
    width: '100%',
    marginTop: '1em',
  },
}))

export const InactivityModal: FC = () => {
  const { t } = useTranslation()
  const { classes } = useStyles()
  const { setNotification } = useNotification()

  const [open, setOpen] = useState(false)
  const [loading, setLoading] = useState(false)

  const [sessionExpirationMins, setSessionExpirationMins] = useState<number>(
    DEFAULT_SESSION_EXPIRATION_MINS
  )

  const [openModalTimeoutId, setOpenModalTimeoutId] = useState<number | null>(
    null
  )

  const [redirectToLoginTimeoutId, setRedirectToLoginTimeoutId] = useState<
    number | null
  >(null)

  const startTimestampRef = useRef<number | null>(null)

  const clearRedirectToLoginTimeout = useCallback(() => {
    if (redirectToLoginTimeoutId) {
      clearTimeout(redirectToLoginTimeoutId)
      setRedirectToLoginTimeoutId(null)
    }
  }, [redirectToLoginTimeoutId])

  const clearOpenModalTimeout = useCallback(() => {
    if (openModalTimeoutId) {
      clearTimeout(openModalTimeoutId)
      setOpenModalTimeoutId(null)
    }
  }, [openModalTimeoutId])

  const clearTimeouts = useCallback(() => {
    clearRedirectToLoginTimeout()
    clearOpenModalTimeout()
  }, [clearRedirectToLoginTimeout, clearOpenModalTimeout])

  const resetRedirectToLoginTimeout = useCallback(
    (ms: number) => {
      if (ms < 0) return
      clearRedirectToLoginTimeout()

      const newRedirectToLoginTimeoutId = setTimeout(() => {
        navigateToUrl('/login')
      }, ms) as unknown as number

      setRedirectToLoginTimeoutId(newRedirectToLoginTimeoutId)
    },
    [clearRedirectToLoginTimeout]
  )

  const resetOpenModalTimeout = useCallback(
    (ms: number) => {
      if (ms < 0) return
      clearOpenModalTimeout()

      const newOpenModalTimeoutId = setTimeout(() => {
        setOpen(true)
      }, ms) as unknown as number

      setOpenModalTimeoutId(newOpenModalTimeoutId)
    },
    [clearOpenModalTimeout]
  )

  const resetTimeouts = useCallback(() => {
    resetRedirectToLoginTimeout(getRedirectToLoginMs(sessionExpirationMins))
    resetOpenModalTimeout(getOpenModalMs(sessionExpirationMins))
    startTimestampRef.current = Date.now()
  }, [
    resetRedirectToLoginTimeout,
    resetOpenModalTimeout,
    sessionExpirationMins,
  ])

  const onClickOK = useCallback(async () => {
    setLoading(true)

    try {
      // If this authorize call is sucessful, the resetAuthorizationTimerEvent
      // that this component is subscribed to will be fired and the
      // timeouts will reset.
      await api.service('users').authorize()
      setOpen(false)
    } catch {
      setNotification({
        type: 'error',
        label: `${t('common.something-went-wrong')}. ${t('common.try-again')}.`,
      })
    } finally {
      setLoading(false)
    }
  }, [])

  useSub(resetAuthorizationTimerEvent.type, resetTimeouts, [resetTimeouts])

  useEffect(() => {
    const localStorageSessionExpirationMins = Number(
      localStorage.getItem(LOCAL_STORAGE_SESSION_EXP_KEY)
    )

    const sessionExpirationMins = !isNaN(localStorageSessionExpirationMins)
      ? localStorageSessionExpirationMins
      : DEFAULT_SESSION_EXPIRATION_MINS

    setSessionExpirationMins(sessionExpirationMins)

    // When the user navigates to a new page, switches tabs, closes the tab,
    // minimizes or closes the browser, it can delay the functionality of
    // setTimeouts/setIntervals, this logic addresses the issue by resetting
    // the timers every time the User comes back to the tab.
    const onVisibilityChange = () => {
      if (!startTimestampRef.current) return

      if (!document.hidden) {
        const msLeftToRedirectToLogin =
          new Date(
            startTimestampRef.current +
              getRedirectToLoginMs(sessionExpirationMins)
          ).getTime() - Date.now()

        const msLeftToOpenModal =
          new Date(
            startTimestampRef.current + getOpenModalMs(sessionExpirationMins)
          ).getTime() - Date.now()

        const openModal = msLeftToOpenModal <= 0

        // While the user wasn't active on the tab the Timeout to redirect
        // to the login delayed, so we check if the time actually expired,
        // if so redirect the user to the Login.
        if (msLeftToRedirectToLogin <= 0) {
          navigateToUrl('/login')
          return
        }
        // While the user wasn't active on the tab the Timeout to open
        // the modal delayed, so we check if the time actually expired,
        // if so we open the modal.
        else if (openModal) {
          setOpen(true)
        }

        resetRedirectToLoginTimeout(msLeftToRedirectToLogin)

        // Only reset the Timeout to open the modal if it wasn't
        // already opened.
        if (!openModal) resetOpenModalTimeout(msLeftToOpenModal)
      }
    }

    // See: https://developer.mozilla.org/en-US/docs/Web/API/Document/visibilitychange_event
    document.addEventListener('visibilitychange', onVisibilityChange)

    return () => {
      document.removeEventListener('visibilitychange', onVisibilityChange)
    }
  }, [resetRedirectToLoginTimeout, resetOpenModalTimeout])

  // Avoid the User from trying to change the Session Exp Time,
  // that would make us to be out of sync with the API Session Exp Time.
  useEffect(() => {
    const onStorageChange = (e: StorageEvent) => {
      if (e.key === LOCAL_STORAGE_SESSION_EXP_KEY) {
        localStorage.setItem(
          LOCAL_STORAGE_SESSION_EXP_KEY,
          String(sessionExpirationMins)
        )
      }
    }

    window.addEventListener('storage', onStorageChange)

    return () => {
      window.removeEventListener('storage', onStorageChange)
    }
  }, [])

  useEffect(() => {
    resetTimeouts()
    return clearTimeouts
  }, [sessionExpirationMins])

  return (
    <Dialog
      open={open}
      classes={{ container: classes.container, paper: classes.paper }}
    >
      <DialogContent className={classes.descriptionContainer}>
        <Typography className={classes.subtitle} variant="h6">
          {t('common.inactivity-modal.your-session-is-about-to-expire')}
        </Typography>

        <Typography className={classes.subtitle} variant="h6">
          {t('common.inactivity-modal.are-you-still-there')}
        </Typography>

        <Typography
          data-testid="action-description"
          className={classes.description}
          component="p"
        >
          <>
            {t('common.inactivity-modal.you-are-going-to-have-to-click')}

            <Typography
              className={classes.descriptionOk}
              variant="body2"
              component="span"
            >{` ${t('common.ok')} `}</Typography>

            {t('common.inactivity-modal.to-stay-logged-in')}
          </>
        </Typography>
      </DialogContent>

      <DialogActions>
        <Button
          testId="confirmation-button"
          containerClassName={classes.actionButton}
          label={t('common.ok')}
          isLoading={loading}
          onClick={onClickOK}
        />
      </DialogActions>
    </Dialog>
  )
}
