import { ApiService, App } from '../../commons'
import { expandQuery } from '../../hooks/expandQuery'
import { mapResult } from '../../hooks/mapResult'
import { paginationQuery } from '../../hooks/paginationQuery'
import { Paylinks } from '../../schemas/types'

export class PaylinksService extends ApiService<Paylinks> {
  async resend(id: string, email: boolean, sms: boolean) {
    return this.apiRequest<Paylinks>({
      url: `${id}/resend?email=${Number(email)}&sms=${Number(sms)}`,
      method: 'PUT',
      headers: {
        'Content-Type': 'application/json',
      },
      body: {},
    })
  }
}

declare module '../../commons' {
  interface ServiceTypes {
    paylinks: PaylinksService
  }
}

export const paylinks = (app: App) => {
  const connection = app.get('connection')

  app.use(
    'paylinks',
    new PaylinksService(connection.service('paylinks'), app),
    {
      methods: ['export'],
    }
  )

  app.service('paylinks').hooks({
    around: {
      all: [paginationQuery(), mapResult(), expandQuery('contact')],
      get: [
        expandQuery(
          'contact',
          'ach_product_transaction',
          'cc_product_transaction',
          'created_user',
          'transactions',
          'log_sms',
          'log_emails',
          'tags'
        ),
      ],
      export: [expandQuery('contact')],
    },
  })
}
