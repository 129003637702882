import { yupResolver } from '@hookform/resolvers/yup'
import { t } from 'i18next'
import { FC, useEffect, useState } from 'react'
import { useForm, Controller, useWatch } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import { tss } from 'tss-react/mui'
import * as yup from 'yup'

import { Box, Typography } from '@mui/material'

import { User, api } from '@shared/api'
import {
  InputPassword,
  TermsAndConditions,
  PasswordRules,
  ActionModal,
} from '@shared/components'
import { useNotification } from '@shared/hooks'

const useStyles = tss.withName('CreateNewPassword').create(({ theme }) => ({
  modal: { minWidth: '700px' },
  content: {
    display: 'flex',
    flexDirection: 'column',
    gap: '20px',
    marginBottom: '24px',
  },
}))

interface FormValues {
  currentPassword?: string
  newPassword?: string
  confirmNewPassword?: string
}

const createPasswordSchema = (resetForOtherUser: boolean) =>
  yup
    .object({
      currentPassword: resetForOtherUser
        ? yup.string().notRequired()
        : yup
            .string()
            .required(t('common.user-profile.enter-your-current-password')),
      newPassword: yup
        .string()
        .required(t('common.validations.enter-a-new-password'))
        .min(8, t('common.validations.must-have-at-least-8-characters'))
        .matches(
          /[a-zA-Z]/,
          t('common.validations.must-have-at-least-1-letter')
        )
        .matches(/\d/, t('common.validations.must-have-at-least-1-number'))
        .matches(
          /(\W|_)/,
          t('common.validations.must-contain-1-special-character')
        ),
      confirmNewPassword: yup
        .string()
        .oneOf(
          [yup.ref('newPassword')],
          t('common.validations.password-doesnt-match')
        ),
    })
    .required()

interface ChangePasswordProps {
  user: User
  open: boolean
  handleClose: () => void
  resetForOtherUser?: boolean
}

export const ChangePassword: FC<ChangePasswordProps> = ({
  user,
  open,
  handleClose,
  resetForOtherUser = false,
}) => {
  const { t } = useTranslation()
  const { classes } = useStyles()
  const { setNotification } = useNotification()

  const schema = createPasswordSchema(resetForOtherUser)

  const {
    handleSubmit,
    control,
    reset,
    trigger,
    formState: { errors, isValid },
  } = useForm<FormValues>({
    defaultValues: {
      currentPassword: '',
      newPassword: '',
      confirmNewPassword: '',
    },
    resolver: yupResolver(schema),
    mode: 'onBlur',
  })

  const newPassword = useWatch({ name: 'newPassword', control })

  const [termsCode, setTermsCode] = useState<string>()
  const [serverErrors, setServerErrors] = useState<{
    currentPassword?: string
    newPassword?: string
  }>({})

  useEffect(() => {
    setServerErrors({})
  }, [newPassword])

  const onSubmit = async (data: FormValues) => {
    setServerErrors({})
    try {
      if (resetForOtherUser) {
        await api
          .service('users')
          .changePassword(user.id, data.newPassword!, null, null)
      } else {
        await api
          .service('users')
          .changePassword(
            user.id,
            data.currentPassword!,
            data.newPassword!,
            termsCode
          )
      }
      setNotification({
        type: 'success',
        label: 'Password changed successfully',
      })
      reset()
      handleClose()
    } catch (error) {
      const customErrors = error?.data?.error?.meta?.errors
      if (customErrors && Object.keys(customErrors).length > 0) {
        setServerErrors({
          currentPassword: customErrors.old_password,
          newPassword: customErrors.password,
        })
      }
      setNotification({
        type: 'error',
        label: 'Error changing password',
      })
    }
  }

  return (
    <ActionModal
      open={open}
      onClose={() => {
        reset()
        handleClose()
      }}
      title={t('common.user-profile.change-password')}
      buttons={[
        {
          label: t('common.cancel'),
          onClick: () => {
            reset()
            handleClose()
          },
          guidingId: 'changepassword-cancel',
        },
        {
          label: t('common.password-reset'),
          onClick: handleSubmit(onSubmit),
          disabled: !isValid,
          testId: 'changepassword-confirm',
          guidingId: 'changepassword-confirm',
        },
      ]}
      className={classes.modal}
      isScrollableContent
      guidingId="changepassword"
    >
      <form onSubmit={handleSubmit(onSubmit)}>
        <Box className={classes.content}>
          <Typography>
            {t(
              'common.user-profile.make-sure-to-not-reuse-any-of-your-last-four-passwords'
            )}
          </Typography>

          {!resetForOtherUser && (
            <Controller
              name="currentPassword"
              control={control}
              render={({ field }) => (
                <InputPassword
                  {...field}
                  testId="currentpassword-input"
                  label={t('common.user-profile.current-password')}
                  placeholder={t(
                    'common.user-profile.enter-your-current-password'
                  )}
                  error={
                    !!errors.currentPassword || !!serverErrors.currentPassword
                  }
                  helperText={
                    errors.currentPassword?.message ||
                    serverErrors.currentPassword
                  }
                  guidingId="changepassword-currentpassword"
                />
              )}
            />
          )}

          <Controller
            name="newPassword"
            control={control}
            render={({ field }) => (
              <InputPassword
                {...field}
                testId="newpassword-input"
                label={t('common.user-profile.new-password')}
                placeholder={t('common.user-profile.enter-your-new-password')}
                error={!!errors.newPassword || !!serverErrors.newPassword}
                helperText={
                  errors.newPassword?.message || serverErrors.newPassword
                }
                guidingId="changepassword-newpassword"
                onBlur={() => {
                  field.onBlur()
                  trigger('confirmNewPassword')
                }}
              />
            )}
          />

          <PasswordRules password={newPassword} />

          <Controller
            name="confirmNewPassword"
            control={control}
            render={({ field }) => (
              <InputPassword
                {...field}
                testId="confirmnewpassword-input"
                label={t('common.user-profile.confirm-new-password')}
                placeholder={t(
                  'common.user-profile.re-enter-your-new-password'
                )}
                error={!!errors.confirmNewPassword}
                helperText={errors.confirmNewPassword?.message}
                guidingId="changepassword-confirmnewpassword"
                onBlur={() => {
                  field.onBlur()
                  trigger('newPassword')
                }}
              />
            )}
          />
        </Box>

        {!resetForOtherUser && (
          <TermsAndConditions
            handleAcceptTermsConditions={(terms) =>
              setTermsCode(terms?.code?.toString())
            }
          />
        )}
      </form>
    </ActionModal>
  )
}
