import { ApiService, App } from '../../commons'
import { mapResult } from '../../hooks/mapResult'
import { Agent } from '../../schemas/types'

export class AgentsService extends ApiService<Agent[]> {}

declare module '../../commons' {
  interface ServiceTypes {
    agents: AgentsService
  }
}

export const partnerPortalAgents = (app: App) => {
  const connection = app.get('connection')

  app.use(
    'agents',
    new AgentsService(connection.service('partner/agents'), app)
  )

  app.service('agents').hooks({
    around: {
      all: [mapResult()],
    },
  })
}
